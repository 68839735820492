import PropTypes from 'prop-types';

// Stying
import "./text.scss";

export const Text = props => {

	return (
        <div className={"vf-text-wrapper" + (props.align ? " " + props.align : "") + (props.disabled ? " disabled" : "")}>
            <div className="vf-text">

                {props.children}

            </div>
        </div>
	);
}

Text.propTypes = {
	align: PropTypes.oneOf(["left", "center", "right"]),
    disabled: PropTypes.bool
};

Text.defaultProps = {
    disabled: false
};

export default Text;