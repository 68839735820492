import React, {
	useEffect,
	useContext,
	useState
} from "react";

// Translations
import {
	useTranslation,
	Trans
} from 'react-i18next';

import {
    StoreContext
} from "../../store/store";

// Action types
import {
	POPUP,
} from "../../constants/actionTypes";

import PopUp from "../PopUp/PopUp";
import PopUpHeader from "../PopUp/PopUpHeader";
import PopUpContent from "../PopUp/PopUpContent";
import PopUpFooter from "../PopUp/PopUpFooter";
import Text from "../Text/Text";

const TermsAndConditions = props => {
	const globalState = useContext(StoreContext);
	const { dispatch } = globalState;

    const { t, i18n } = useTranslation();

    const terms = window.termsAndCondition.terms;
    
	useEffect(() => {
		return () => {
		};

	}, []);

    const closePopUp = () => {
        dispatch({
			type: POPUP,
			popup: false
		});
    }

	return (
        <PopUp onClickOutSide={closePopUp}>
            <PopUpHeader onCloseButtonClick={closePopUp}>
                {window.termsAndCondition.header.title} {window.termsAndCondition.header.desc}
            </PopUpHeader>
            <PopUpContent>
                <Text align={"left"}>
                    <p dangerouslySetInnerHTML={{ __html: window.termsAndCondition.header.descDetail }}></p>
                </Text>

                {terms.map((term, i) => {
                    return (
                        <div key={i}>
                            <Text align={"left"}>
                                <p><strong><small dangerouslySetInnerHTML={{ __html: term.title }}></small></strong></p>
                                <br />
                                <p dangerouslySetInnerHTML={{ __html: term.desc }}></p>
                            </Text>
                        </div>
                    )
                })}
            </PopUpContent>
            <PopUpFooter>
                <span dangerouslySetInnerHTML={{ __html: window.termsAndCondition.dt }}></span>
            </PopUpFooter>
        </PopUp>
	);
}

export default TermsAndConditions;
