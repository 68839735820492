import Parser from 'html-react-parser';
//Translation function
export const text = (key, arg, parse = true) => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageLook = urlParams.get('page_look') ? urlParams.get('page_look') : "";
    const translations = urlParams.get("text");

    if(key !== undefined && typeof(key) !== 'object'){
        // if(pageLook){
        //     key = pageLook + "." + key;
        // }
    }

    // if( translations == "keys"){
    //     return key;
    // }

    if(window.loc !== undefined){
        var value = window.loc[key];

        if( window.loc["react." + key] ){
            value = window.loc["react." + key];
            key = "react." + key;
        }

        // Show translation keys
        if( translations == "keys"){
            // Avoid braking text for reCAPTCHA
            if(key !== "label.for.recaptcha"){
                if(key !== "false"){
                    return key;
                }
            }
        }

        // if(pageLook){
        //     if( window.loc[pageLook + "." + key] ){
        //         value = window.loc[pageLook + "." + key];
        //     }
        // }

        if (value) {
            if(arg !== null && arg !== undefined && typeof(arg) === 'object'){

                // console.log("-------");
                // console.log("key: " + key);
                // console.log("arg: " + arg);
                // console.log("value: " + value);

                var placeholderRegex = "";
                if(arg.length >= 1 && arg[0] !== undefined){
                    //if(typeof(arg[0]) === 'object'){
                        for (var i = 0; i <= arg.length; i++) {
                            var arg_value = arg[i];
                            placeholderRegex = '\\{' + (i + 1) + '\\}'; // {1}, {2}, etc
                            //console.log(placeholderRegex);
                            if(arg_value === 'link'){
                                let link = value.substring(value.indexOf('{') + 1 , value.indexOf('}')) ;
                                let linkTag = '';
                                if(key == 'user.login.error.message' && ['ie'].indexOf(window.countryCode) !== -1){
                                    linkTag = '<a href="' + arg[i+1] + '" target="_self" >'+ link +'</a>' ;
                                }else{
                                    linkTag = '<a href="' + arg[i+1] + '" target="_blank" >'+ link +'</a>' ;
                                }
                                link = '\\{' + link + '\\}';
                                value = value.replace(new RegExp(link, 'g'), linkTag);
                                //break;
                            }
                            value = value.replace(new RegExp(placeholderRegex, 'g'), arg_value);
                        }
                    // }else{
                    //     placeholderRegex = '\\{1\\}'; // {1}, {2}, etc
                    //     value = value.replace(new RegExp(placeholderRegex, 'g'), arg[0]);
                    // }
                }
            }

            // return Parser(value)
            if(parse === true){
                return Parser (value);
            }else{
                // Show translation values
                return value
            }
        }else{
            // Show missing translations keys
            // if(key !== "false"){
            //     return key;
            // }
        }
    }
}

export const extractLocale = () =>{
    var locale = window.location.href;
    if(locale.indexOf('ui_locales') !== -1){
        locale = locale.substring(locale.indexOf('ui_locales=') + 1,locale.length);
        if(locale.indexOf('&') !== -1){
            locale = locale.substring(0,locale.indexOf('&'));
        }
        if(locale.indexOf('#') !== -1){
            locale = locale.substring(0,locale.indexOf('#'));
        }
    }
    else{
        locale = navigator.language;
    }
    extractLanguage(locale);
    if(locale.length > 2){
        locale = locale.substring(locale.length-2,locale.length);
    }

    return locale.toUpperCase();
}

export const extractLanguage = (locale) =>{
    window.countryLang = locale.substring(0,2);
}

export const onLinkAction = (href, target) => {
    if(target === '_blank' ){
        window.open(href);
    }else{
        window.location.href = href;
    }
}

export const appHandleRedirect = (redirectLink) => {
    //Delete Cookie
    deleteCookie( 'Auth_' + extractUrlParameter('state') );
    if(getCookieByName('Selfcare_'+ extractUrlParameter('state')) !== undefined){
        deleteCookie('Selfcare_' + extractUrlParameter('state'));
    }

    if( redirectLink ){
        if(redirectLink.href !== undefined && (redirectLink.href).indexOf('logout') !== -1){
            //this.appGetData( redirectLink );
        }else{
            window.location.assign( redirectLink.href );
        }
    }else{
        window.location.assign( redirectLink.href );
    }

}

export const checkWebView = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();

    if( /iphone|ipod|ipad/.test( userAgent ) && !(/safari/.test( userAgent ))){
        return '_self';
    }else if( /android/.test(userAgent) && ( /wv/.test( userAgent ) || /version/.test( userAgent ) ) ){
        return '_self'
    }else{
       return '_blank';
    }
}

export const getCookieByName = ( cname ) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent( document.cookie );
    let ca = decodedCookie.split(';');

    for( let i = 0; i < ca.length; i++ ){
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if(c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const extractUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&:]' + name + '=([^&#:]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const extractRedirectURI = () => {
    let url = window.location.search.substr(0, window.location.search.indexOf('#')-1);
    return url === null ? '' : url;
}

export const createSessionCookie = () => {
    var state = extractUrlParameter('state');
    if(state !== undefined && state !== null && state !== ""){
        if(getCookieByName('Auth_'+state) === "" || getCookieByName('Auth_'+state) === undefined || getCookieByName('Auth_'+state) === null ){
        //this.clearSessionCookie();
        var sec =  process.env.REACT_APP_SESSION_TIMEOUT_IN_SEC
        if(sec > 0){
            var expiry_time = new Date().getTime() + sec * 1000;
            var data = {"epochExpTime": expiry_time}
            document.cookie = "Auth_"+ state +"=" + btoa(JSON.stringify(data)) + ";expires=" + (new Date( expiry_time )).toUTCString() + ";path=/;domain=localhost" ;
            /*document.cookie = "Auth_"+ state +"=" + btoa(JSON.stringify(data)) + ";expires=" + (new Date( expiry_time )).toUTCString() + ";path=/;" ;*/
            window.config.expiryTime = data;
            }
        }else{
            let sessionTimeoutInSeconds = JSON.parse(atob(getCookieByName('Auth_'+state)));
                sessionTimeoutInSeconds = Math.round((sessionTimeoutInSeconds.epochExpTime - new Date().getTime
            ())/1000);
            if(sessionTimeoutInSeconds > 0 ){
                window.config.expiryTime = JSON.parse(atob(getCookieByName('Auth_'+state)));
            }else{
                clearSessionCookie();
                createSessionCookie();
            }
        }
    }
}

export const setCookie = (cname, cvalue, cdomain, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    if(cdomain){
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + cdomain ;
    }else{
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" ;
    }
}

export const deleteCookie = ( cname ) => {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const clearSessionCookie = () => {
    var state = extractUrlParameter('state');
    document.cookie = "Auth_" + state + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

//Encrypt the password
export const encryptPassword = ( link , pwd ) => {
    var crypto = link.crypto;
    var encrypedPwd;
    if( crypto ){
        encrypedPwd = window.encryptPassword(pwd, crypto.modulus, crypto.exponent, crypto.appendix);
        return encrypedPwd;
    }
}

export const countryMarket = (nvFlow, countryCode) => {
    let market = undefined;
    if(window.config !== undefined){
        //Check which flow NV /VF
        let msisdnPrefix = window.config.market.internationalPrefix;
        //let countryCode = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
        if(nvFlow === true){
            //NV Flow
            if(countryCode){
                // if( window.config.countries && window.config.countries[countryCode] ){
                //     msisdnPrefix = window.config.countries[countryCode];
                // }
                window.config.market.countries.map(function(cValue){
                    if(cValue.countryCode === countryCode){
                        msisdnPrefix = cValue.internationalPrefix;
                    }
                });

                if(msisdnPrefix === undefined){
                    msisdnPrefix = '00';
                }

                market = {
                    countryCode: countryCode,
                    msisdnPrefix: msisdnPrefix
                }
            }
        }else{
            //VF Flow
            market = {
                countryCode: window.config.market["countryCode"],
                msisdnPrefix: window.config.market.internationalPrefix
            }
        }
     }
     return market;
}

export const validateIdentifier = (identifierType, identifierValue, contextName) => {
    let expression = null;
        let minLength, maxLength = undefined;
        switch(identifierType){
            case 'email':
                minLength = window.config.market['minEmailChars'];
                maxLength = window.config.market['maxEmailChars'];
                expression = escape(window.config.market['emailRegEx']);
                break;
            case 'tel':
                minLength = window.config.market['minMsisdnChars'];
                maxLength = window.config.market['maxMsisdnChars'];
                expression = escape(window.config.market['msisdnRegEx']);
                break;
            case 'username':
                minLength = window.config.market['minUsernameChars'];
                maxLength = window.config.market['maxUsernameChars'];
                expression = escape(window.config.market['usernameRegEx']);
                break;
            case 'nif':
                minLength = window.config.market['minNifChars'];
                maxLength = window.config.market['maxNifChars'];
                expression = escape(window.config.market['nifRegEx']);
                break;
            default:
                minLength = maxLength = undefined;
        }

        expression = expression.replace(/%5C%5C/g,'%5C');
        expression = unescape(expression);

        let regEx = new RegExp(expression);
        if(identifierValue){
            if(minLength !== -1 && identifierValue.length < minLength){
                //setErrors(errors => ({ ...errors, 'msg': text('validation.error.min.length.message.identifier', minLength) }));
                return (text('validation.error.min.length.message.identifier', minLength));
            }else if(maxLength !== -1 && identifierValue.length > maxLength){
                //setErrors(errors => ({ ...errors, 'msg': text('validation.error.max.length.message.identifier', maxLength) }));
                return (text('validation.error.max.length.message.identifier', maxLength));
            }else if(expression !== "undefined" && expression !== 'null' && expression !== ''){
                let valid = regEx.test(identifierValue);
                //Check for Context - VALIDATE_ES_USERNAME
                if((contextName === 'VALIDATE_ES_USERNAME' && valid === false) || (contextName === 'AUTHENTICATE' && window.config.market.countryCode === 'IE' && valid === false)){
                    let re = new  RegExp(/^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);
                    valid = re.test(identifierValue);
                }
                if(!valid){
                    switch(identifierType){
                        case "tel":
                            //setErrors(errors => ({ ...errors, 'msg': text('validation.error.message.identifier.msisdn') }));
                            return (text('validation.error.message.identifier.msisdn'));
                            break;
                        case "email":
                            //setErrors(errors => ({ ...errors, 'msg': text('validation.error.message.identifier.email') }));
                            return (text('validation.error.message.identifier.email'));
                            break;
                        case "username":
                            //setErrors(errors => ({ ...errors, 'msg': text('validation.error.message.identifier.username') }));
                            return (text('validation.error.message.identifier.username'));
                            break;
                        case "nif":
                            //setErrors(errors => ({ ...errors, 'msg': text('validation.error.message.identifier.nif') }));
                            return (text('validation.error.message.identifier.nif') )
                            break;
                        default:
                            console.log("WARNING: Please check the identifier type!");
                            //setErrors(errors => ({ ...errors, 'msg': text('validation.error.message.identifier') }));
                            return (text('validation.error.message.identifier'));
                        }
                }else{
                    return false;
                }
            }else{
                return false
            }
        }
}
