import React from "react";
//import "./Radio.scss";

const radioStyles = {
    "display": "block",
    "marginLeft": "30px",
    "marginBottom":"34px",
    "fontSize":"14px",
    "lineHeight":"18px",
    "color":"#333333"
}

const RadioOptions = props => {
  return (
    // <div className="radio_option_wrap">
    //     <input className="radio_option" id={props.id} type="radio" name={props.name} value={props.value} checked={props.checked} onChange={props.action}/>
    //     <label className={"radio_option_label"} htmlFor={props.id}>{props.label}</label>
    // </div>
    <>

    <label className="radioContainer">{props.label}
        <input type="radio" id={props.id} checked={props.checked} name={props.name} value={props.value} onChange={props.action} />
        <span className="checkmark"></span>
    </label>
    </>
  );
};

export default RadioOptions;
