import React from "react";

const CifSection = props => {
  return (
    <div className="section">
    	<div className="cifSection">CIF:&nbsp;{props.cif}</div>
    </div>
  );
};

export default CifSection;
