import React from "react";

const Account = props => {
    let address = [];
    let address_button = "";
    for (var key in props.account){
        if(key === "accountId"){
            var id = props.account[key];
            if(props.cif !== undefined){
                id = id + '_' + props.cif
            }

            address_button = <i className="arrow" id={id} onClick={(event) => props.onAccountSelectHandler(event.target.id)}></i>
        }else{
            address.push(<div key={key}> {props.account[key]} </div>);
        }
    }

    return(
    <div className="section">
        <div className ="addrSelectionWrap">
            <div className = "addressSection">
                {address}
            </div>
            <div className = "addrButton">
                {address_button}
            </div>
        </div>
    </div>
    );
};

export default Account;
