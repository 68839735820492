
import React, { useState, useRef,useEffect,useContext } from "react";
import IdleTimer from 'react-idle-timer';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import {extractUrlParameter} from '../../utils/global';
import { StoreContext } from "../../store/store";

import {
  SPLASH_LOAD
} from "../../constants/actionTypes";

function SessionKiller(props) {

let timerid = useState(-1);
var state = extractUrlParameter('state');
let trxForCookie = 'Auth_' + state;
//console.log('Authorize:::Call::Auth_cookie_details::',JSON.parse(Base64.atob(cookies2.get(trxForCookie))));
let authCookieData = {};
let whatTimeRem = 0;
let getErrorUri = props.sessionURL;
let idleTimer = null;
let idpCurrentTime = null, diffCurentTime = null;

const { dispatch } = useContext(StoreContext);
if(Cookies.get(trxForCookie)){
  console.log('Authorize:::Call::Auth_cookie_details::',JSON.parse(Base64.atob(Cookies.get(trxForCookie))));
  authCookieData = JSON.parse(Base64.atob(Cookies.get(trxForCookie)));
  idpCurrentTime = new Date().getTime();
  diffCurentTime = idpCurrentTime - authCookieData.currentTime;
  whatTimeRem = authCookieData.epochExpTime - (authCookieData.currentTime + diffCurentTime);
  getErrorUri = authCookieData["error-url"];
}
let sessionTimeInMs = whatTimeRem;
let timeout = sessionTimeInMs;
//Setting to mins to display on console
whatTimeRem = whatTimeRem /1000;

useEffect(() => {
    clearTimeout({timerid});
    getSessionTimeout();
    elapsedTimeCounter();
}, []);

function getSessionTimeout()
  {
      if ({timerid})
      {
          clearTimeout(timerid);
      }
      setTimeout(() =>
      {
        dispatch({
          type: SPLASH_LOAD,
          splash: true
        });
        console.log('Redirect to error URI after transaction timeout');
        //VFLogger.info("Transaction timeout Called!");
          //VFLogger.info('Transaction timeout : Discard session','time_remaining: '+sessionTimeInMs+'page'+pageContext);
          Cookies.remove(trxForCookie);
          //VFLogger.info('Redirect to error URI after transaction timeout','page:'+pageContext);
          window.location.href = getErrorUri;
          //setSessionEndsSoon(true);
      }, sessionTimeInMs);
  }
  function elapsedTimeCounter(authCookieData){
    setInterval(function(){
    //   var currentTime = new Date().getTime();
    //   var minutes = Math.floor(Math.round((authCookieData.epochExpTime - currentTime) / 1000) / 60 );
    //   var seconds = Math.round((authCookieData.epochExpTime - currentTime) / 1000) - minutes * 60;
    //   var remainingTime = (minutes < 10 ? '0' : '') + minutes + "m " + (seconds < 10 ? '0' : '') + seconds+"s";

    //   if(Math.round((authCookieData.epochExpTime - currentTime) / 1000) <= 60){
    //     seconds = Math.round((authCookieData.epochExpTime - currentTime) / 1000);
    //     remainingTime = (seconds < 10 ? '0' : '') + seconds+"s";
    //   }
    //   setElapsedTime(remainingTime);
    //   //console.warn("Cureent Session Time Remaining::::",remainingTime);
    //   if(Math.round((authCookieData.epochExpTime - currentTime) / 1000) <= 0){
    //     remainingTime = "0s";
    //     setElapsedTime(remainingTime);
     // }

      whatTimeRem = Math.floor(whatTimeRem - 1);
      if( whatTimeRem > 60 ){
        // Show minutes and seconds
        // console.log( "Tick - " + (Math.floor(whatTimeRem / 60)) + ":" + whatTimeRem % 60 );
      }else if(whatTimeRem > 0){
        // Show seconds
        // console.log( "Tick - " + whatTimeRem );
      }

    },1000);
  }

  function _onAction(e) {
    //console.log('User seen active and action on screen::', e);
  }

  function _onActive(e) {
      Cookies.remove(trxForCookie);
        //console.log('user is active', e)
        //console.log('time remaining', idleTimer.getRemainingTime())
  }

  function _onIdle(e) {
    // console.log('Clear All Cookies manually');
    // console.log('processURL --> start processing URL manually');
    // console.log('last active', idleTimer.getLastActiveTime());
    //console.log('URL from Auth Cookie::',authCookieData["error-url"]);
    Cookies.remove(trxForCookie);
    window.location.href = getErrorUri;
  }

  const DEFAULT_EVENTS = [
    'mousemove',
    //'keydown',
    'wheel',
    'DOMMouseScroll',
    'mouseWheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
  ]

  return (
      <>
        <IdleTimer
          ref={ref => { idleTimer = ref }}
          element={document}
          onActive={_onActive}
          onIdle={_onIdle}
          events={DEFAULT_EVENTS}
          debounce={250}
          onAction={_onAction}
          timeout={timeout} />
      </>
  );
}
export default SessionKiller;
