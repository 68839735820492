import { getTopDomainName } from "./utils";
import { text } from "./global";
import Cookies from 'js-cookie';
import analyticsTealium from "./Analytics/analytics";
import useSMAPI from "../components/SMAPI/useSMAPI";

const cookieConsent = () => {

    const { setTealiumActive } = analyticsTealium();
    const { toggleSMAPI } = useSMAPI();

    const checkShowBanner = ()  => {

        let showBanner = false;
        //Add a config check to show cookie banner
        if(window.config.cookieManagement.cookieBannerEnabled) {

                //OPTION 1 : Check the application cookie for cookie preference
                let cookiesConsent;
                let consent = {};
                try {
                    const consentCookieRawValue = Cookies.get("consent");
                    if (consentCookieRawValue) {
                        cookiesConsent = window.atob(consentCookieRawValue);
                        consent = JSON.parse(cookiesConsent);
                    }
                } catch (err) {
                    console.warn("Can't read consent setting. Consent was reset");
                    console.error(err);
                }

                 //OPTION 2 : Check the utag_data_bridge for cookie preference
                 const utagDataBridge = window.utag_data_bridge;

                 //OPTION 3 : Check Query parameter for cookie preference
                 const searchParams = new URLSearchParams(window.location.search);

                if (cookiesConsent){ //OPTION : 1

                    let necessaryCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.necessary").split(", ") || [];
                    let performanceCookieList = [], marketingCookieList = [], functionalCookieList = [];

                    /*Check cookies list exists in application set true: show banner set false: hide banner*/
                    if(window.config.smapiEnabled){
                        performanceCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.optional").split(", ");
                    }
                    
                    if(window.config.analytics && window.config.analytics.tealium_enabled){
                       //TO DO add the marketing tranaslation cookie list
                        marketingCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.marketing").split(", ");
                    }

                    if(window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){
                        functionalCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.functional").split(", ");
                     }


                    if(necessaryCookieList.length > 0){
                        const necessaryCookiesArray = consent["necessary"] && consent["necessary"]["cookies"] ? Object.keys(consent["necessary"]["cookies"]) : [];
                        if(necessaryCookiesArray.length == 0){
                            showBanner = true;
                        }
                    }

                    if(functionalCookieList.length > 0){
                        const functionalCookieListArray = consent["functional"] && consent["functional"]["cookies"] ? Object.keys(consent["functional"]["cookies"]) : [];
                        functionalCookieListArray.forEach((key) => {
                            if (!showBanner && !functionalCookieListArray.includes(key)){
                                showBanner = true;
                            }
                        });
                    }

                    if(performanceCookieList.length > 0){
                        const performanceCookiesArray = consent["performance"] && consent["performance"]["cookies"] ? Object.keys(consent["performance"]["cookies"]) : [];
                        performanceCookieList.forEach((key) => {
                            if (!showBanner && !performanceCookiesArray.includes(key)){
                                showBanner = true;
                            }
                        });
                    }

                    if(marketingCookieList.length > 0){
                        const marketingCookiesArray = consent["marketing"] && consent["marketing"]["cookies"] ? Object.keys(consent["marketing"]["cookies"]) : [];
                        marketingCookieList.forEach((key) => {
                            if (!showBanner && !marketingCookiesArray.includes(key)){
                                showBanner = true;
                            }
                        });
                    }

                }else if (utagDataBridge) { //OPTION : 2

                    if((window.config.analytics && !window.config.analytics.tealium_enabled) || !window.config.smapiEnabled ){
                        if(!utagDataBridge.hasOwnProperty('visitor_permission_strictly_necessary')){
                            showBanner = true;
                        }
                    }

                    if(window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){
                        if(!utagDataBridge.hasOwnProperty('visitor_permission_functional')){
                            showBanner = true;
                        }
                    }

                    if(window.config.smapiEnabled){
                        if(!utagDataBridge.hasOwnProperty('visitor_permission_performance')){
                            showBanner = true;
                        }
                    }

                    if(window.config.analytics && window.config.analytics.tealium_enabled){
                        if(!utagDataBridge.hasOwnProperty('visitor_permission_targeting')){
                            showBanner = true;
                        }
                    }

                }else if (searchParams.has('cc')) { //OPTION : 3
                    const queryCookieConsent = searchParams.get('cc');

                        if((window.config.analytics && !window.config.analytics.tealium_enabled) || !window.config.smapiEnabled || (window.config.cookieManagement && !window.config.cookieManagement.functionalCookiesEnabled)){
                            if(queryCookieConsent.indexOf('r') < 0){
                                showBanner = true;
                            }
                        }

                        if(window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){
                            if(queryCookieConsent.indexOf('f') < 0){
                                showBanner = true;
                            }
                        }

                        if(window.config.smapiEnabled){
                            if(queryCookieConsent.indexOf('p') < 0){
                                showBanner = true;
                            }
                        }

                         if(window.config.analytics && window.config.analytics.tealium_enabled){
                            if(queryCookieConsent.indexOf('m') < 0){
                                showBanner = true;
                            }
                         }

                }else{

                    showBanner = true;
                }
        }
        return showBanner;
    }

    const getCookieConsent = (cookie) => {
        let applicationCookieState = false;

        //Add the check to show cookie banner or cookie manage link is enable
        if(window.config && (window.config.cookieManagement.cookieBannerEnabled || window.config.cookieManagement.manageCookieLinkEnabled)) {

            //OPTION 1 : Check the utag_data_bridge for cookie preference
            const utagDataBridge = window.utag_data_bridge;

            //OPTION 2 : Check Query parameter for cookie preference
            const searchParams = new URLSearchParams(window.location.search);

            //OPTION 3 : Check the application cookie for cookie preference

            let consent;
            try {
                const consentCookieRawValue = Cookies.get('consent');

                if (consentCookieRawValue) {
                    const cookiesConsentDecoded = window.atob(consentCookieRawValue);
                    consent = JSON.parse(cookiesConsentDecoded);
                }
            } catch (err) {
                console.warn("Can't read consent setting. Consent was reset");
                console.error(err);
            }

            if(consent){ //OPTION : 1

                /* Check the cookie state in the application cookie list*/
                if(consent[cookie] && consent[cookie]["cookies"]){
                    const tempArray = [];
                    let cookieList = [];

                    if(cookie == "performance" && window.config.smapiEnabled){
                        cookieList = text("cookieManager.aboutCookiePopup.cookieUseList.optional").split(", ");
                    }else if(cookie == "marketing" && window.config.analytics && window.config.analytics.tealium_enabled){
                       //TO DO add the marketing tranaslation cookie list
                        cookieList = text("cookieManager.aboutCookiePopup.cookieUseList.marketing").split(", ");
                    }
                     //check functional cookie
                    else if(cookie == 'functional' && window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){
                        cookieList = text("cookieManager.aboutCookiePopup.cookieUseList.functional").split(", ");
                    }

                    const applicaitonConsentCookieList = consent[cookie]["cookies"];
                    cookieList.map((cookie) => {
                        if(applicaitonConsentCookieList.hasOwnProperty(cookie)){
                            tempArray.push(applicaitonConsentCookieList[cookie]);
                        }else{
                            tempArray.push(false);
                        }
                    });

                    const trueCount = tempArray.filter(Boolean).length;
                    const falseCount = tempArray.filter((boolValue) => {return(boolValue == false)}).length;


                    if(falseCount > 0){
                        /* Set false if falseCount is greater than 0*/
                        applicationCookieState = false;
                    }else if(trueCount > 0 && falseCount == 0){
                        /*Set true if trueCount is greater than 0 and falseCount is 0*/
                        applicationCookieState = true;
                    }
                }
            }else if (utagDataBridge) { //OPTION : 2

                    //check functional cookie
                    if(cookie == 'functional' && utagDataBridge.hasOwnProperty('visitor_permission_functional')){
                        applicationCookieState = utagDataBridge['visitor_permission_functional'].toLowerCase() == 'granted' ? true : false;
                    }

                    //check performance cookie
                    if(cookie == 'performance' && window.config.smapiEnabled && utagDataBridge.hasOwnProperty('visitor_permission_performance')){
                        applicationCookieState = utagDataBridge['visitor_permission_performance'].toLowerCase() == 'granted' ? true : false;
                    }

                    //check marketing cookie
                    if(cookie == 'marketing' && window.config.analytics && window.config.analytics.tealium_enabled && utagDataBridge.hasOwnProperty('visitor_permission_targeting')){
                        applicationCookieState = utagDataBridge['visitor_permission_targeting'].toLowerCase() == 'granted' ? true : false;
                    }

                    

            }else if(searchParams.has('cc')){ //OPTION : 3
                const queryCookieConsent = searchParams.get('cc');
                //check performance cookie
                if(cookie == 'performance' && window.config.smapiEnabled && queryCookieConsent.indexOf('p') > 0){
                    applicationCookieState = queryCookieConsent.charAt(queryCookieConsent.indexOf('p') + 1).toLowerCase() == 'y' ? true : false;
                }

                //check marketing cookie
                if(cookie == 'marketing' && window.config.analytics && window.config.analytics.tealium_enabled && queryCookieConsent.indexOf('m') > 0){
                    applicationCookieState = queryCookieConsent.charAt(queryCookieConsent.indexOf('m') + 1).toLowerCase() == 'y' ? true : false;
                }

                //check functional cookie
                if(cookie == 'functional' && window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled && queryCookieConsent.indexOf('f') > 0){
                    applicationCookieState = queryCookieConsent.charAt(queryCookieConsent.indexOf('f') + 1).toLowerCase() == 'y' ? true : false;
                }
            }
        }
        return applicationCookieState;
    }

    const saveCookieConsent = (cookieValue) => {
        /*Save the cookies in the top domain*/

        let consent = {};
        try {
            const consentCookieRawValue = Cookies.get("consent");

            if (consentCookieRawValue) {
                const consentDecodedValue = window.atob(consentCookieRawValue)
                consent = JSON.parse(consentDecodedValue);
            }
        } catch (err) {
            console.warn("Can't read consent setting. Consent was reset");
            console.error(err);
        }

        let cookies = {};

        //Add Necessary cookie list
        const necessaryCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.necessary").split(", ");
        if(necessaryCookieList){

            necessaryCookieList.map((cookie) => {
            cookies[cookie] = true;
            });

            let cookieDetails = {
            on: new Date()
            };

            if(consent['necessary']) {
                cookieDetails["cookies"] =  { ...consent['necessary'].cookies, ...cookies};
            }else{
                cookieDetails["cookies"] =  cookies;
            }

            consent['necessary'] = cookieDetails;
        }

        //Add functional cookie list
        cookies = {};
        const functionalCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.functional").split(", ");
        if(functionalCookieList && window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){

        functionalCookieList.map((cookie) => {
            cookies[cookie] = cookieValue;
            if(!cookieValue){
                document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }
            });

            let cookieDetails = {
            on: new Date()
            };

            if(consent['functional']) {
                cookieDetails["cookies"] =  { ...consent['functional'].cookies, ...cookies};
            }else{
                cookieDetails["cookies"] =  cookies;
            }

            consent['functional'] = cookieDetails;
        }

        cookies = {};
        //Add Performance cookie list (smapi)
        const performanceCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.optional").split(", ");
        if(performanceCookieList.length > 0 && window.config.smapiEnabled){

            performanceCookieList.map((cookie) => {
                cookies[cookie] = cookieValue;
                if(!cookieValue){
                    if(sessionStorage.getItem(cookie)){
                      sessionStorage.removeItem(cookie);
                    }else{
                      document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    }
                }
            });

            let cookieDetails = {
                on: new Date()
            };

            if(consent['performance']) {
                cookieDetails["cookies"] =  { ...consent['performance'].cookies, ...cookies};
            }else{
                cookieDetails["cookies"] =  cookies;
            }

            consent['performance'] = cookieDetails;

            //ON / OFF the smapi
            toggleSMAPI(cookieValue);
        }

        cookies = {};
        //Add marketing cookie list translation (tealium)
        const marketingCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.marketing").split(", ");
        if(marketingCookieList.length > 0 && window.config.analytics && window.config.analytics.tealium_enabled){
            marketingCookieList.map((cookie) => {
                cookies[cookie] = cookieValue;

                if(!cookieValue){
                    document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + getTopDomainName();
                }
            });

            let cookieDetails = {
                on: new Date()
            };

            if(consent['marketing']) {
                cookieDetails["cookies"] =  { ...consent['marketing'].cookies, ...cookies};
            }else{
                cookieDetails["cookies"] =  cookies;
            }

            consent['marketing'] = cookieDetails;

            //ON / OFF the marketing
            setTealiumActive(cookieValue);
        }

         /*Remove and Create the cookie consent */
        Cookies.remove("consent");
        const consentEncoded = window.btoa(JSON.stringify(consent));
        Cookies.set("consent", consentEncoded, { path: '/', domain: getTopDomainName(), expires: 183 });

    }

    const initializeCookieConsent = () => {
        let consent = {};
        try {
            const consentCookieRawValue = Cookies.get("consent");
            if (consentCookieRawValue) {
                consent = JSON.parse(window.atob(consentCookieRawValue));
            }
        } catch (err) {
            console.warn("Can't read consent setting. Consent was reset");
            console.error(err);
        }

        //OPTION 1 : Check the utag_data_bridge for cookie preference
        const utagDataBridge = window.utag_data_bridge;

        //OPTION 2 : Check Query parameter for cookie preference
        const searchParams = new URLSearchParams(window.location.search);


        let performanceState = false;
        let marketingState = false;
        let createConsent = false;
        let functionalState = false;

        const necessaryCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.necessary").split(", ");
        const performanceCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.optional").split(", ");
        const functionalCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.functional").split(", ");
        const marketingCookieList = text("cookieManager.aboutCookiePopup.cookieUseList.marketing").split(", ");

        if (utagDataBridge) { //OPTION : 1


            //check necessary cookie
            if(necessaryCookieList.length > 0 && utagDataBridge.hasOwnProperty('visitor_permission_strictly_necessary')){
                //Create cookie consent
                createConsent = true;
            }

            //check functional cookie
            if(functionalCookieList.length > 0 && utagDataBridge.hasOwnProperty('visitor_permission_functional')){
                //Create cookie consent
                functionalState = utagDataBridge['visitor_permission_functional'].toLowerCase() == 'granted' ? true : false;
                createConsent = true;
            }

            //check performance cookie
            if(performanceCookieList.length > 0 && window.config.smapiEnabled  && utagDataBridge.hasOwnProperty('visitor_permission_performance')){
                performanceState = utagDataBridge['visitor_permission_performance'].toLowerCase() == 'granted' ? true : false;
                //Create cookie consent
                createConsent = true;
            }

            //check marketing cookie
            if(marketingCookieList.length > 0 && window.config.analytics && window.config.analytics.tealium_enabled && utagDataBridge.hasOwnProperty('visitor_permission_targeting')){
                marketingState = utagDataBridge['visitor_permission_targeting'].toLowerCase() == 'granted' ? true : false;
                //Create cookie consent
                createConsent = true;
            }

            /* Remove cc , t_v_id and a_v_id from query parameter */
              removeTealiumValues(true)

        }else if(searchParams.has('cc')){ //OPTION : 2
                const queryCookieConsent = searchParams.get('cc');
                //check necessary cookie
                if(queryCookieConsent.indexOf('r') > -1){
                    //Create cookie consent
                    createConsent = true;
                }

                //check functional cookie
                if(functionalCookieList.length > 0 && window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled && queryCookieConsent.indexOf('f') > -1){
                    //Create cookie consent
                    createConsent = true;
                    functionalState = queryCookieConsent.charAt(queryCookieConsent.indexOf('f') + 1).toLowerCase() == 'y' ? true : false;
                }
                
                //check performance cookie
                if(performanceCookieList.length > 0 && window.config.smapiEnabled && queryCookieConsent.indexOf('p') > -1){
                    performanceState = queryCookieConsent.charAt(queryCookieConsent.indexOf('p') + 1).toLowerCase() == 'y' ? true : false;
                    //Create cookie consent
                    createConsent = true;
                }
                

                //check marketing cookie
                if(marketingCookieList.length > 0 && window.config.analytics && queryCookieConsent.indexOf('m') > -1){
                    marketingState = queryCookieConsent.charAt(queryCookieConsent.indexOf('m') + 1).toLowerCase() == 'y' ? true : false;
                    //Create cookie consent
                    createConsent = true;
                    if(!marketingState){
                        //Remove tealium visitor ID and adobe visitor from query parameter
                        removeTealiumValues();
                    }
                }else if(marketingCookieList.length > 0 && window.config.analytics && queryCookieConsent.indexOf('m') == -1){
                    //Remove tealium visitor ID and adobe visitor from query parameter
                    removeTealiumValues();
                }
                /* Remove cc from query parameter */
                if( history ){
                    const newURL = new URL(window.location.href);
                    newURL.searchParams.delete('cc');
                    if( history.state ){
                    history.replaceState(history.state, history.context, newURL);
                    }else{
                    history.replaceState(null, '', newURL);
                    }
                }
                
        }else{
            //Remove tealium visitor ID and adobe visitor from query parameter
            removeTealiumValues();
        }

        if(createConsent){
             let cookies = {};

             if(necessaryCookieList.length > 0){
                necessaryCookieList.map((cookie) => {
                    cookies[cookie] = true;
                });

                let cookieDetails = {
                    on: new Date()
                };

                if(consent['necessary']) {
                    cookieDetails["cookies"] =  { ...consent['necessary'].cookies, ...cookies};
                }else{
                    cookieDetails["cookies"] =  cookies;
                }

                consent['necessary'] = cookieDetails;

             }

             if(performanceCookieList.length > 0 && window.config.smapiEnabled){
                cookies = {};
                performanceCookieList.map((cookie) => {
                    cookies[cookie] = performanceState;
                });

                let cookieDetails = {
                    on: new Date()
                };

                if(consent['performance']) {
                    cookieDetails["cookies"] =  { ...consent['performance'].cookies, ...cookies};
                }else{
                    cookieDetails["cookies"] =  cookies;
                }

                consent['performance'] = cookieDetails;
             }

             //ON / OFF the smapi
             toggleSMAPI(performanceState);
             if(functionalCookieList.length > 0 && window.config.cookieManagement && window.config.cookieManagement.functionalCookiesEnabled){
                cookies = {};
                functionalCookieList.map((cookie) => {
                    cookies[cookie] = functionalState;
                });

                let cookieDetails = {
                    on: new Date()
                };

                if(consent['functional']) {
                    cookieDetails["cookies"] =  { ...consent['functional'].cookies, ...cookies};
                }else{
                    cookieDetails["cookies"] =  cookies;
                }

                consent['functional'] = cookieDetails;
             }


             if(marketingCookieList.length > 0 && window.config.analytics && window.config.analytics.tealium_enabled){
                cookies = {};
                marketingCookieList.map((cookie) => {
                    cookies[cookie] = marketingState;
                });

                let cookieDetails = {
                    on: new Date()
                };

                if(consent['marketing']) {
                    cookieDetails["cookies"] =  { ...consent['marketing'].cookies, ...cookies};
                }else{
                    cookieDetails["cookies"] =  cookies;
                }

                consent['marketing'] = cookieDetails;
            }

            //ON / OFF the marketing
            setTealiumActive(marketingState);

            /*Remove and Create the cookie consent */
            const consentEncoded = window.btoa(JSON.stringify(consent));
            Cookies.set("consent", consentEncoded, { path: '/', domain: getTopDomainName() , expires: 183 });
        }else{
            //OFF the smapi
            toggleSMAPI(getCookieConsent('performance'));
            //OFF the marketing
            setTealiumActive(getCookieConsent('marketing'));
        }

        dispatch({
            type: BANNER,
            banner: checkShowBanner()
        });
    }

    const removeTealiumValues = (removeCC) => {
        const newURL = new URL(window.location.href);
        const existingURL = new URL(window.location.href);
        const searchParams = new URLSearchParams(window.location.search);
        const tealiumVisitorID = searchParams.get('t_v_id');
        const tealiumAdobeID = searchParams.get('a_v_id');

        if(tealiumVisitorID){
            newURL.searchParams.delete('t_v_id');
        }

        if(tealiumAdobeID){
            newURL.searchParams.delete('a_v_id');
        }

        if(removeCC){
            newURL.searchParams.delete('cc');
        }

        if(newURL.href !== existingURL.href){
            if( history.state ){
               history.replaceState(history.state, history.context, newURL);
            }else{
               history.replaceState(null, '', newURL);
            }
        }
    }

    return {
        checkShowBanner,
        saveCookieConsent,
        getCookieConsent,
        initializeCookieConsent
    }

}

export default cookieConsent;