import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { StoreContext } from '../../store/store';
import ServiceRequest from '../../utils/Services';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ServiceLogo from '../ServiceLogo/ServiceLogo';
import VFLogger from '../../utils/Logger';
import SectionTitle from '../SectionTitle/SectionTitle';
import FormErrorBox from '../FormErrorBox/FormErrorBox';
import { text, extractUrlParameter } from '../../utils/global';
import Validate from '../Validate/Validate';
import Authenticate from '../Authenticate/Authenticate';
import WelcomeBack from '../WelcomeBack/WelcomeBack';
import ConfirmPin from '../ConfirmPin/ConfirmPin';
import NonVodafoneEnabled from '../NonVodafoneEnabled/NonVodafoneEnabled';
import Registration from '../Registration/Registration';
import EmailVerification from '../EmailVerification/EmailVerification';
import Error from '../Error/Error.js';

import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import ManageCookies from '../ManageCookies/ManageCookies';
import CookieBanner from '../CookieBanner/CookieBanner';

import { RESPONSE_SUCCESS, RESPONSE_ERROR, RESPONSE_ERROR_CLEAR, INITIAL_ERROR } from '../../constants/actionTypes';

import SessionKiller from '../Session/SessionKiller';
import EndFlow from '../EndFlow/EndFlow';
import UpdateProfile from '../UpdateProfile/UpdateProfile';
import EndFlowLoading from '../EndFlowLoading/EndFlowLoading';
import AccountSelection from '../AccountSelection/AccountSelection';
import SelfcareKYC from '../SelfcareKYC/SelfcareKYC';

import Link from '../Link/Link';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner.js';

import { configRequest } from '../../utils/Services';
import { setConfigs } from '../../utils/initialConfigs.js';
import analyticsTealium from "../../utils/Analytics/analytics";
import cookieConsent from "../../utils/cookieConsent";
import { getPageName } from '../../constants/tealiumMapper.js';
export const AuthorizeMain = () => {
  const { state, dispatch } = useContext(StoreContext);
  let authState = 'Auth_' + extractUrlParameter('state');

  const { initializeTealium, trackPageView, trackPageEvent} = analyticsTealium();
  const { initializeCookieConsent } = cookieConsent();

  let contextRender = false,
    backendMsg = false,
    title = false,
    subTitle = false,
    serviceLogo = false,
    titleLogo = false,
    sessionTimer = false,
    context = '';
  let inputErr = '',
    splash = '';

  useEffect(() => {
    console.log('AuthorizeMain loaded');
    VFLogger.log('Environment=', process.env);
    configRequest(dispatch)
      .then((resp) => {
        setConfigs(resp, dispatch)
          .then(() => {
            authCall();
            initializeTealium();
            initializeCookieConsent();

          })
          .catch((error) => {
            dispatch({
              type: INITIAL_ERROR,
              payload: error.response,
            });
          });
      })
      .catch((error) => {
        RequestCallBack(error);
      });
  }, []);

  function authCall() {
    let authorize = process.env.REACT_APP_AUTHORIZE_ENDPOINT;

    // Hash Implementation -- Call the correct web service according to hash value in url
    var queryString = window.location.search.substr(1);
    var url;

    var hash = window.location.hash.substring(1, window.location.hash.length + 1); // strip off the #
    var key = '';
    if (hash.indexOf('::') != -1) {
      key = hash.substring(hash.lastIndexOf('::') + '::'.length);
      hash = hash.substring(0, hash.lastIndexOf('::'));
    }

    switch (hash) {
      case 'email':
        url = '/web-apis/validate/email/' + key;
        break;
      case 'password':
        url = '/web-apis/password-reset/validate/email/' + key;
        break;
      case 'password-federated':
        url = '/web-apis/v2/password-reset/validate/email/' + key;
        break;
      case 'account':
        url = '/web-apis/selfcare?' + queryString;
        break;
      case 'kyc_confirm':
        url = '/web-apis/selfcare/kyc?' + queryString;
        break;
      case 'finaliseRegistration':
        var token;
        token = queryString.substring(queryString.indexOf('=') + 1, queryString.length);
        url = '/web-apis/externalregistration/finalise/' + token;
        break;
      case 'finaliseResetPassword':
        var token;
        token = queryString.substring(queryString.indexOf('=') + 1, queryString.length);
        url = '/web-apis/externalreset/finalise/' + token;
        break;
      case '':
        //Only for development - local
        if (process.env.NODE_ENV !== 'development') {
          url = authorize + '?' + queryString;
        } else {
          url = authorize;
        }
        break;
      default:
        if (hash == '/register') {
          url = '/web-apis/authorize?' + queryString;
          break;
        }
        if (hash.indexOf('/') == 0) {
          url = '/web-apis' + hash + '?' + queryString;
        } else {
          url = '/web-apis/' + hash + '?' + queryString;
        }
    }

    // let url = authorize + '?' + window.location.search.substr(1);
    if (process.env.NODE_ENV !== 'development') {
      url = process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_IAM_ENDPOINT + url;

      url = window.location.href.substr(0, 11) + url.substr(8);
    }

    // Console Log
    console.log('URL for first authorize call : ' + url);

    VFLogger.log('URL :: ' + url);

    let requestUrl = {
      method: 'get',
      href: url,
    };
    ServiceRequest(requestUrl, null, RequestCallBack);
  }

  function RequestCallBack(response) {
    console.log("Response to callback=", response, response.status);
    if (response !== undefined && response.status === 200) {
    //Track the page view event in tealium
      trackPageView(getPageName(response.data.context), {flowName: "login", flowType: "login" });
      dispatch({
        type: RESPONSE_SUCCESS,
        payload: response.data,
      });
    } else {
      // VFLogger.request({
      //   event_desc: 'Ajax esrror request',
      //   event_msg: 'URL: ' + '',
      //   resp_code: response.status,
      //   isMobile:
      //     'App running on mobile: ' + window.isMobile === undefined || window.isMobile === '' ? '' : window.isMobile,
      // });
      let statusText = 'oops.message';
      let keyArgs = undefined;
      if (response !== '' && response.data.key !== undefined) {
        statusText = response.data.key;
      }
      if (response !== '' && response.data.arguments !== undefined) {
        keyArgs = response.data.arguments;
      }
      dispatch({
        type: RESPONSE_ERROR,
        payload: {
          status: response.status,
          key: statusText,
          arguments: keyArgs,
        },
      });
    }
    // VFLogger.request({ event_desc : 'Ajax success request', event_msg : 'URL: ' + '' , resp_code : response.status, isMobile : 'App running on mobile: ' + window.isMobile === undefined || window.isMobile === '' ? '' : window.isMobile });
  }

  const clearErrorMessage = () => {
    dispatch({
      type: RESPONSE_ERROR_CLEAR,
      payload: {},
    });
  };

  if (state.data !== undefined && state.data !== null && state.data.context !== undefined) {
    VFLogger.log(state);
    window.context = state.data;
    context = state.data.context.toLowerCase();

    switch (state.data.context) {
      case 'VALIDATE':
      case 'VALIDATE_GB_USERNAME':
      case 'VALIDATE_GB_REGISTER':
      case 'VALIDATE_FORGOT_USERNAME':
      case 'MSISDN_INPUT':
      case 'VALIDATE_ES_USERNAME':
        contextRender = <Validate RequestCallBack={RequestCallBack}></Validate>;
        break;
      case 'AUTHENTICATE':
        contextRender = <Authenticate RequestCallBack={RequestCallBack}></Authenticate>;
        break;
      case 'WELCOME_BACK':
        contextRender = <WelcomeBack RequestCallBack={RequestCallBack}></WelcomeBack>;
        break;
      case 'CONFIRM_PIN':
        contextRender = <ConfirmPin RequestCallBack={RequestCallBack}></ConfirmPin>;
        break;
      case 'NON_VODAFONE_ENABLED':
        contextRender = <NonVodafoneEnabled RequestCallBack={RequestCallBack}></NonVodafoneEnabled>;
        break;
      case 'REGISTRATION':
      case 'CONFIRM_RESET_PASSWORD':
      case 'CONFIRM_PIN_PASSWORD':
        contextRender = <Registration RequestCallBack={RequestCallBack}></Registration>;
        break;
      case 'UPDATE_PROFILE':
        contextRender = <UpdateProfile RequestCallBack={RequestCallBack}></UpdateProfile>;
        break;
      case 'ACCOUNT_SELECTION':
        contextRender = <AccountSelection RequestCallBack={RequestCallBack}></AccountSelection>;
        break;
      case 'SELFCARE_KYC':
        contextRender = <SelfcareKYC RequestCallBack={RequestCallBack}></SelfcareKYC>;
        break;
      case 'ES_EMAIL_VERIFICATION':
        contextRender = <EmailVerification RequestCallBack={RequestCallBack} />;

        // secondary.page.title.for.es_email_verification.page = Verifica tu correo electrónico
        // introduction.to.es_email_verification.page = Verifica en tu bandeja de entrada el correo electrónico de confirmación y pulsa en el enlace que te hemos enviado.
        // secondary.introduction.to.es_email_verification.page = Después de verificar tu correo electrónico, por favor, vuelve atrás y pulsa Continuar.

        break;
      case 'END_FLOW':
      case 'END_FLOW_SUCCESS':
        contextRender = <EndFlow RequestCallBack={RequestCallBack}></EndFlow>;
        break;
      case 'END_FLOW_LOADING':
        contextRender = <EndFlowLoading RequestCallBack={RequestCallBack}></EndFlowLoading>;
        break;
      case 'FOLLOW':
        ServiceRequest(state.data.links['urn:vodafoneid:follow'], null, null, 'self');
        break;
    }

    // Service Logo
    if (
      state.data.context !== 'CONFIRM_PIN' &&
      state.data.context !== 'END_FLOW_LOADING' &&
      state.data.isPwdResetFlow !== true
    ) {
      serviceLogo = <ServiceLogo></ServiceLogo>;
      titleLogo = '';
    }

    // Title and Sub Title
    if (
      state.data.context !== 'CONFIRM_PIN' &&
      state.data.context !== 'END_FLOW_LOADING' &&
      state.data.context !== 'END_FLOW' &&
      state.data.context !== 'NON_VODAFONE_ENABLED' &&
      state.data.context !== 'UPDATE_PROFILE'
    ) {
      let contextName = state.data.context;
      if (contextName === 'END_FLOW_LOADING') {
        contextName = 'CONFIRM_PIN';
      }
      title = 'page.title.for.' + contextName.toLowerCase() + '.page';
      // As per new design subtitle should not be there
      // subTitle = 'page.intro.for.'+ contextName +'.page';
    }

    // Logo, Title and subTitle for Confirm Pin success flow
    if (state.data.context === 'CONFIRM_PIN') {
      serviceLogo = <ServiceLogo serviceClass="success"></ServiceLogo>;
      title = 'page.title.for.confirm_pin.page';
      //subTitle = 'page.subtitle.for.password.reset.success.page';
      context = '';
    }

    // Logo, Title and subTitle for Password reset success flow
    if (state.data.isPwdResetFlow === true && state.data.context === 'END_FLOW') {
      serviceLogo = <ServiceLogo serviceClass="success"></ServiceLogo>;
      title = 'page.title.for.password.reset.success.page';
      subTitle = 'page.subtitle.for.password.reset.success.page';
      context = '';
    }

    // Logo, Title and  subTitle for Success Registration flow
    if (state.data.context === 'END_FLOW_SUCCESS') {
      serviceLogo = <ServiceLogo serviceClass="success"></ServiceLogo>;
      title = 'page.title.for.registration.success.page';
      subTitle = 'page.subtitle.for.registration.success.page';
      context = '';
    }

    // Logo, Title and subTitle for OneApp
    if (state.data.context === 'AUTHENTICATE' && state.page_look === 'mva') {
      subTitle = 'page.subtitle.for.authenticate.page';
      context = '';
    }

    // Backend Messages
    // if((state.data.message && state.data.context !== 'CONFIRM_PIN' && state.data.context !== 'END_FLOW_LOADING') || (state.errorObj && state.errorObj.msg)){
    if (
      (state.errorObj && state.errorObj.msg) ||
      (state.data.context === 'END_FLOW' && state.data.message !== undefined)
    ) {
      // if((state.errorObj && state.errorObj.msg)){

      let errorMsg = '';
      let msgType = '';
      if (state.errorObj.msg) {
        // Check if Message is text or key
        if (state.errorObj.text === 'true') {
          errorMsg = state.errorObj.msg;
        } else {
          errorMsg = text(state.errorObj.msg, state.errorObj.arguments);
        }
        msgType = state.errorObj.type;
      } else {
        // Check if Message is text or key
        if (state.errorObj.text === 'true') {
          errorMsg = state.data.message.value;
        } else {
          errorMsg = text(state.data.message.value, state.data.message.arguments);
        }
        // inputErr = 'input_error';
        msgType = state.data.message.type;
      }

      console.log('window.config', window.config);
      if (state.page_look === 'mva') {
        if (state.data.context === 'END_FLOW') {
          backendMsg = (
            <div className="vf-error-wrapper">
              <div className="vf-error">
                <div className="vf-error-icon-wrapper">
                  <div className="vf-error-icon"></div>
                </div>
                <div className="vf-error-title">
                  {state.data.message && state.data.message.type === 'error' ? text('oops.intro') : ''}
                </div>
                <div className="vf-error-text">
                  {state.data.message ? text(state.data.message.value) : text('oops.message')}
                </div>
              </div>
            </div>
          );
        } else {
          backendMsg = (
            <div className="toast-message-overlay">
              <div className="toast-message error">
                <div className="toast-message-icon"></div>
                <div className="toast-message-title">{text('incorrect.details.error.message.title')}</div>
                <div className="toast-message-text">
                  <p>{text('incorrect.details.error.message')}</p>

                  {/* <p>We couldn’t recognise your phone number or password. Please try again.</p>
                                            <p>If you can’t remember your password, use the reset password link below.</p> */}
                </div>
                <div className="toast-message-controls">
                <Link
                    id="forgotPassword"
                    label={'Reset password'}
                    handleLink={() => {
                    trackPageEvent(getPageName('AUTHENTICATE_WRONG_CREDENTIALS'), {eventCategory: "link", eventLabel:'Reset password' }), 
                    ServiceRequest(state.data.links['urn:vodafoneid:resetpassword'], null, null, 'self')}}
                ></Link>

                <Button 
                        type={'primary'} 
                        label={'Try again'} 
                        id={'try-again'} 
                        handleClick={()=> {trackPageEvent(getPageName('AUTHENTICATE_WRONG_CREDENTIALS'), {eventCategory: "button", eventLabel: 'Try again' }),
                          clearErrorMessage()}}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else {
        // This is normally error from form side, no 500 error
        backendMsg = (
          <FormErrorBox
            messageClass="form-error"
            error={errorMsg}
            iconType={`icon_${msgType}`}
            messageType={`message_${msgType}`}
          />
        );
      }
    }

    // Session Timer
    if (window.config !== undefined && state.data.isAuthenticated === false) {
      // Check Auth cookie is exist or not
      if (Cookies.get(authState)) {
        VFLogger.log('Session is getting started');

        sessionTimer = (
          <SessionKiller sessionURL={state.data.links['urn:vodafoneid:sessionTimeout'].href}></SessionKiller>
        );
      } else {
        var sec =
          process.env.REACT_APP_SESSION_TIMEOUT_IN_SEC !== undefined ? process.env.REACT_APP_SESSION_TIMEOUT_IN_SEC : 0;

        if (sec > 0) {
          var expiry_time = new Date().getTime() + sec * 1000;

          // var data = {"epochExpTime": 1601977842534,
          // "currentTime": 1601977242534,
          // "error-url": state.data.links['urn:vodafoneid:sessionTimeout'].href}

          var currentTime = new Date().getTime();
          var data = {
            epochExpTime: expiry_time,
            currentTime: currentTime,
            'error-url': state.data.links['urn:vodafoneid:sessionTimeout'].href,
          };
          // document.cookie = "Auth_"+ state +"=" + btoa(JSON.stringify(data)) + ";expires=" + (new Date( expiry_time )).toUTCString() + ";path=/;domain=localhost" ;
          VFLogger.log('Creating session cookie');
          Cookies.set("authState", btoa(JSON.stringify(data)), { path: '/' });
        }
      }
    }
  }

  console.log("state",  state)

  if (state.isLoading) {
    return <Spinner />;
  }
  return (
    <div className={'outer-wrapper ' + state.page_look}>
      <Header />
      {state.popup && (
        <>
          {state.popup === 'TERMS_AND_CONDITIONS' ? (
            <TermsAndConditions />
          ) : state.popup === 'PRIVACY_POLICY' || state.popup === 'COOKIE_POLICY' ? (
            <PrivacyPolicy />
          ) : state.popup === 'MANAGE_COOKIES' ? (
            <ManageCookies />
          ) : (
            <></>
          )}
        </>
      )}
      {state.page_look !== 'mva' && <CookieBanner />}
      <div className="main-wrapper">
        <div className="wrapper-inner">
          {serviceLogo}

          {state.isError && state.errorObj && state.errorObj.msg && state.page_look !== 'mva' && (
            <FormErrorBox
              messageClass="form-error"
              error={text(state.errorObj.msg)}
              iconType={`icon_${state.errorObj.type}`}
              messageType={`message_${state.errorObj.type}`}
            />
          )}
          {state.isError && state.errorObj && state.errorObj.msg && state.page_look === 'mva' && (
            <Error text={text(state.errorObj.msg)} />
          )}

          <form noValidate className={context}>
            <div className={`section-form-panel ${inputErr}`}>
              <SectionTitle className={titleLogo} title={title} subTitle={subTitle}></SectionTitle>

              {backendMsg}
              {contextRender}
            </div>
          </form>
        </div>
      </div>

      <Footer
        showTncFooter={state.terms}
        showPPFooter={state.privacy}
      />

      {sessionTimer}
    </div>
  );
};

export default AuthorizeMain;
