import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './assets/scss/style.scss';
import './assets/scss/_mva.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from './store/store';
import './i18n';
import packageJson from '../package.json';
window.version = packageJson.version;
console.log('APP TAG VERSION=', packageJson.version);
ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
