import React, { Component } from 'react';
import './Timer.scss';

class Timer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text,
            countdown: this.props.countdown,
            units: this.props.units,
            display: this.props.display
        };

        this.startTimer = this.startTimer.bind(this);
        this.timerTick = this.timerTick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            text: nextProps.text,
            countdown: nextProps.countdown,
            units: nextProps.units,
            display: nextProps.display
        });
    }

    componentDidMount(){
        clearInterval( this.timer );
        if( this.state.countdown ){
            this.startTimer();
        }
    }

    componentWillUnmount(){
        clearInterval( this.timer );
    }

    startTimer(){
        this.timer = setInterval( this.timerTick, 1000 );
    }

    timerTick(){
        // Tick
        if( this.state.countdown > 1 ){
            this.setState({
                countdown: this.state.countdown - 1
            });
        /// Time is out
        }else{
            clearInterval( this.timer );

            if( this.props.action ){
                this.props.action();
            }
        }

        // Loggint tick
        //if (window.Config.showLogs) {
            if( this.state.countdown > 60 ){
                // Show minutes and seconds
                // console.log( "Tick - " + Math.floor(this.state.countdown / 60) + ":" + this.state.countdown % 60 );
            }else{
                // Show seconds
                // console.log( "Tick - " + this.state.countdown );
            }
        //}
    }

    render() {
        let className = 'timer';
        let classCount = 'countdown';
        //let timerText = false;
        let sessionText = false;

        if(this.state.display === true){
            sessionText = this.state.countdown;
            return (
                <div className="timer_wrap">
                <div className={className}>

                    <div className={classCount}>
                        {sessionText}
                    </div>

                    <p>{this.state.units}</p>
                    </div>
                </div>
            );
        }else{
            return false;
        }

    }
}

export default Timer;