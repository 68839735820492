import React from "react";
import "./Button.scss";

const Button = props => {
	return (
		<button
			className={`button ${props.type}`}
			id={props.id}
			type="button"
			data-testid={props.dataTestID}
			onClick={props.handleClick}
		>
			<span className="button_label">{props.label}</span>
			<i className="spinner"></i>
		</button>
	);
};

export default Button;
