import React, { useEffect, useContext } from "react";
const Spinner = props => {
    useEffect ( () =>{
        const options = {
            container: document.getElementById('spinner'),
            renderer: 'svg',
            loop: true,
            path: "spinner.json"
        }
        lottie.loadAnimation(options);
        lottie.play();

        return () => {
            lottie.stop()
        }
    }, [])
    
    return (
        <div className="vf-splash">
            <div id="spinner" className="vf-splash-spinner"></div>
        </div>
    );    
};

export default Spinner;
