import React, {
	useContext,
} from "react";

import {
    StoreContext
} from "../../store/store";

import "./pop-up.scss";

const PopUpFooter = props => {
	return (
        <div className="vf-popup-footer-wrapper">
            <div className="vf-popup-footer">
                {props.children}
            </div>
        </div>
    )
}

export default PopUpFooter;
