import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../../store/store';
import "./ServiceLogo.scss";

const ServiceLogo = props => {
    let { state } = useContext(StoreContext);
    const [serviceLogoUrl, setServiceLogoUrl] = useState(process.env.PUBLIC_URL  + '/padlock_close.svg');
    // const[serviceName,setServiceName]  = useState('');

    useEffect(() => {
      if(state.data != null && state.data !== undefined && state.data.clientInfo !== undefined && state.data.clientInfo !== null){
        // setServiceName(state.data.clientInfo.client_name !== undefined && state.data.clientInfo.client_name !== null ? state.data.clientInfo.client_name : text('page.title'));
        setServiceLogoUrl(state.data.clientInfo.logo_url !== undefined && state.data.clientInfo.logo_url !== null ? state.data.clientInfo.logo_url : process.env.PUBLIC_URL + '/padlock_close.svg');
      }
      if(props.serviceClass !== undefined){
        setServiceLogoUrl(process.env.PUBLIC_URL  + '/tick_green_hifi.svg');
      }
      // else{
      //   setServiceName(text('page.title'));
      // }
    });

  return (
    <div className="service-head">
        <div className="service-logo">
        <img src={ serviceLogoUrl } alt = "Title Icon" ></img>
        </div>
        {/* <div className="service-title securenet">{serviceName}</div> */}
    </div>
  );
};

export default ServiceLogo;
