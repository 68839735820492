import React from "react";
import "./Link.scss";

const Link = (props) => {
  return (
    <>
    <span
      className={
        `link-wrapper` +
        (props.className === undefined ? "" : " " + props.className)
      }
    >

      {props.intro && <React.Fragment>{props.intro}&nbsp;</React.Fragment>}
      <a id={props.id} onClick={props.handleLink}>
        {props.label}
      </a>
      </span>
    </>
  );
};

export default Link;
