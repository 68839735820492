import React, { createContext, useReducer } from "react";
import * as actionTypes  from '../constants/actionTypes';

export const StoreContext = createContext();

const urlParams = new URLSearchParams(window.location.search);
const pageLook = urlParams.get('page_look');

const initialState = {
    page_look: pageLook ? pageLook.toLocaleLowerCase() : "",
    isLoading: true,
    isError: false,
    terms: false,
    privacy: false,
    cookie: false,
    errorObj: {},
    fields: {},
    data: null,
    market: "",
    popup: false,
	banner: true,
};

const initialReducer = (state, action) => {
    switch (action.type){
        case actionTypes.INITIAL_START:
            return{
                ...state,
                isLoading: true,
                isError: false,
                config: null,
                fields: {}
            };
        case actionTypes.SPLASH_LOAD:
            return{
                ...state,
                isLoading: action.splash
            };
        case actionTypes.SETTING_TERMS:
            return{
                ...state,
                terms: action.terms
            };
        case actionTypes.SETTING_PRIVACY:
            return{
                ...state,
                privacy: action.privacy
            };
        case actionTypes.SETTING_COOKIE:
            return{
                ...state,
                cookie: action.cookie
            };
            case actionTypes.RESPONSE_SUCCESS:
            let isError = false;
            let errorObj = {};
            let loading = false;
            if (
                action.payload &&
                action.payload.status &&
                typeof action.payload.status === "object"
            ) {
                isError = true;
                errorObj = {
                    "msg" : 'oops.message',
                };
            }
            //Check the FOLLOW context
            if(action.payload.context !== undefined && action.payload.context === 'FOLLOW'){
                loading = true;
            }
            //Check the message in the response
            if(action.payload.context !== undefined && action.payload.context !== 'END_FLOW' && action.payload.context !== 'END_FLOW_LOADING' && action.payload.message !== undefined){
                errorObj = {
                    "msg" : action.payload.message.value,
                    "type" : action.payload.message.type !== undefined ? action.payload.message.type : 'error',
                    "text" : action.payload.message.text !== undefined ? action.payload.message.text : 'false',
                    "arguments" : action.payload.message.arguments !== undefined ? action.payload.message.arguments : null
                }
            }
            return {
                ...state,
                isLoading: loading,
                isError: isError,
                errorObj: errorObj,
                //isError: false,
                //errorObj: {"msg":"invalid.password.error.message"},
                data: action.payload,
                page_look:(action.payload && action.payload.pageLook) ? action.payload.pageLook : state.page_look ?  state.page_look : "default"
            };
        case actionTypes.RESPONSE_ERROR:
            let errObj = {};
            let dataJson = false;
            if ( action.payload && action.payload.status ) {
                if(action.payload.status >= 500){
                    //Oops Error Screens
                    dataJson = false;
                    state.data = null;
                    errObj = {
                        "msg" : 'oops.message',
                    };
                }else{
                    //if (window.config.showLogs) {
                        console.log( "RESPOND:" );
                        console.log( action.payload );
                        console.log( "ERROR: Server reached, but it returned an error: " + JSON.stringify(action.payload) );
                    //}

                    if( action.payload !== undefined && action.payload.key !== undefined ){
                        dataJson = true;
                        errObj = {
                            "msg" : action.payload.key,
                            "arguments" : action.payload.arguments
                        };
                    } else {
                        //if (window.config.showLogs) {
                            console.log("Received a non-5XX error but there was no localisation key so showing the oops page");
                        //}
                        dataJson = true;
                        errObj = {
                            "msg" : 'oops.message',
                        };
                    }
                }

                console.log(`error ${ JSON.stringify(errObj)}`)
            }
            return {
                ...state,
                data : dataJson === true? state.data : null,
                isLoading: false,
                isError: state.data === null ? true : false,
                errorObj: errObj,
                page_look:(action.payload && action.payload.pageLook) ? action.payload.pageLook : state.page_look ?  state.page_look : "default"
                };
        case actionTypes.RESPONSE_ERROR_CLEAR:
            return {


                ...state,
                errorObj: {}
                };
        case actionTypes.INITIAL_SUCCESS:
            isError = false;
            errObj = {};
            if (
                action.payload &&
                action.payload.status &&
                typeof action.payload.status === "object"
            ) {
                isError = true;
                errObj = action.payload.status;
            }
            return {
                ...state,
                isLoading: false,
                isError: isError,
                errorObj: errObj,
                data: action.payload,
                page_look:(action.payload && action.payload.pageLook) ? action.payload.pageLook : state.page_look ?  state.page_look : "default"
            };
        case actionTypes.INITIAL_ERROR:
            let errObj1 = {};
            if ( action.payload && action.payload.status ) {
                errObj1 = {
                    "status" : action.payload.status,
                    "statusText" : action.payload.statusText
                };
                }
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorObj: errObj1,
                page_look:(action.payload && action.payload.pageLook) ? action.payload.pageLook : state.page_look ?  state.page_look : "default"
                };
        case actionTypes.POPUP:
            return {
                ...state,
                popup: action.popup,
                banner: false
            };
        case actionTypes.BANNER:
            return {
                ...state,
                popup: false,
                banner: action.banner
            };
        default:
            return state;

    }
};

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(initialReducer, initialState );
    const value = { state, dispatch };

    return (
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    );
};
