import React from "react";
import './FormErrorBox.scss';

const FormErrorBox = props => {
  let messageType = props.iconType.replace("icon", "message");

  return (
    <div className={`input_message_wrap ${props.iconType}`}>
      <div className='input_message_icon_wrap'>
        <div className={`input_message_icon ${props.iconType}`}></div></div>
        <div className={`input_message ${messageType}`}>{props.error}</div>
    </div>
  );
};

export default FormErrorBox;