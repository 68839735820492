import React, { useContext } from "react";
import './Header.scss';

import { StoreContext } from "../../store/store";

import axios from "axios";
import analyticsTealium from "../../utils/Analytics/analytics";
const { trackPageEvent} = analyticsTealium();
import { getPageName } from '../../constants/tealiumMapper.js';

import packageJson from '../../../package.json';

import {
    RESPONSE_SUCCESS

} from "../../constants/actionTypes";

function Header() {
	const { state, dispatch } = useContext(StoreContext);

	const version = packageJson.version;

	const backToService = () => {
		console.log("backToService");

		const url = state.data.links["urn:vodafoneid:backtoservice"].href;

		const options = {
			headers: { 'Content-type':'application/json' }
		};

		axios.get(url, {withCredentials: true, options})
			.then(response => {
				console.log("--- response ---");
				console.log(response);

				dispatch({
					type: RESPONSE_SUCCESS,
					payload: response.data
				});
			
			})
			.catch(error => {
				console.log("--- error ---");
			});
	}

	return (
		<header className="fixed-top">
			{state.data && state.data.links && state.data.links["urn:vodafoneid:backtoservice"] &&
				<button 
					className="button icon backToService" 
					onClick={() => {
							trackPageEvent(getPageName(state.data.context), {eventCategory: "button", eventLabel: 'Back to service' }),
							backToService()
						}
					}>
				</button>
			}
			
			<nav className="navbar navbar-expand-md navbar-dark  navbar-vf">
				<div className="container-fluid">
					<div className="logo_wrapper">
						<div className="logo logo_vf"></div>
					</div>
					<div style={{display: "none"}}>{version}</div>
				</div>
			</nav>
		</header>
	);
}
export default Header;
