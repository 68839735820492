import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../../store/store';
import {text} from '../../utils/global';

const SectionTitle = props => {
  let { state } = useContext(StoreContext);
  let serviceName  = '';
  const[pageTitle, setPageTitle] = useState('');
  useEffect(() => {
    if(state.data != null && state.data !== undefined && state.data.clientInfo !== undefined && state.data.clientInfo !== null){
      serviceName = (state.data.clientInfo.client_name !== undefined && state.data.clientInfo.client_name !== null ? state.data.clientInfo.client_name : text('page.title'));
    }
    else{
      serviceName = text('page.title');
    }
    // console.log(serviceName);
    // console.log(props.title)
    setPageTitle (text(props.title,[serviceName]));
    // setPageTitle('Log in to Service Name');
  });
  if(props.title !== false || props.subTitle !== false){
    return (
      <div className="section-head">
          <div className={`${props.className}`}></div>
          {/* Title */}
          <p className="section-title">{pageTitle}</p>
          {/* Sub Title */}
          <p className="section-subtitle">{text(props.subTitle)}</p>
      </div>
    );
  }else{
    return false;
  }
};

export default SectionTitle;
