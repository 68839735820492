import React from "react";

import "./banner.scss";

const Banner = props => {

	return (
		<>
			<div className="vf-banner-wrapper">
				<div className="vf-banner">

                    <div className="vf-banner-text-wrapper desktop">
						<p>{props.text}</p>
                    </div>

                    <div className="vf-banner-text-wrapper mobile">
						<p>{props.textMobile}</p>
                    </div>

                    <div className="vf-banner-controls-wrapper">
                        {props.children}
                    </div>

				</div>
			</div>
		</>
	);
};
export default Banner;
