import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Link from '../Link/Link';
import FormErrorBox from '../FormErrorBox/FormErrorBox';
import { text, encryptPassword, countryMarket } from "../../utils/global";
import { ServiceRequest, handleLink } from '../../utils/Services';
import useForm from "./useForm";
import VFLogger from '../../utils/Logger';
import InputOTP from '../InputOTP/InputOTP';
import analyticsTealium from "../../utils/Analytics/analytics";
import "./Registration.scss";

const Registration = props => {
	let { state } = useContext(StoreContext);
	let buttons = [];
	const { trackPageEvent } = analyticsTealium();
	let passwordTag, confirmPrdTag;
	const [passwordType, setPasswordType] = useState("password");
	const [passwordIcon, setPasswordIcon] = useState("show");
	const [confirmPwdType, setConfirmPwdType] = useState("password");
	const [confirmPwdIcon, setConfirmPwdIcon] = useState("show");
	let market = undefined, pwdRules = "", confirmPin = false, resendPin = false, identifierDisplay = false;
	const [subTitle, setSubTitle] = useState("");

	const {
		errors,
		errorsForCTA,
		values,
		OTP,
		setOTP,
		confirmPwdTitle,
		setConfirmPwdTitle,
		confirmPwdPadding,
		setConfirmPwdPadding,
		titleClassName,
		setTitleClassName,
		paddingClassName,
		setPaddingClassName,
		upperCase,
		setUpperCase,
		lowerCase,
		setLowerCase,
		numericCase,
		setNumericCase,
		specialCase,
		setSpecialCase,
		eightcharacter,
		setEgithCharacter,
		setErrors,
		buttonType,
		setButtonType,
		handleSubmit,
		handleChange,
		onMouseEnterHandler,
		onMouseLeaveHandler,
		resetValues,
		handleBlur
	} = useForm(submitForm);

	//DOM load event
	useEffect(() => {
		//On Error Clear the screen
		if (state.errorObj && state.errorObj.msg) {
			resetValues();
		}
	}, [state.errorObj]);

	useEffect(() => {
		//Set Sub Title for Confirm Pin Password
		setSubTitle(text('page.intro.for.confirm_pin_password.page'));
	}, []);

	useEffect(() => {
		//Logger for error message
		if (errors.pwdMsg !== '') {
			VFLogger.client({
				event_desc: 'Password does not meet the requirement',
				event_msg: '',
				resp_code: '',
				page: state.data.context,
				fragment: state.data.fragment !== undefined ? state.data.fragment : '',
				market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
				service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
			});
		}

		if (errors.confirmPwdMsg !== '') {
			VFLogger.client({
				event_desc: 'Passwords do not match',
				event_msg: '',
				resp_code: '',
				page: state.data.context,
				fragment: state.data.fragment !== undefined ? state.data.fragment : '',
				market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
				service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
			});
		}

	}, [errors])

	const handleOTPChange = (OTP) => {
		setOTP(OTP);

		if(OTP.length == 6){
			setButtonType("primary");
		}else{
			setButtonType("primary disabled");
		}
	}

	//Get Market details
	//if(state.data.identifier){
	let country = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
	market = countryMarket(state.data.nv, country);
	//}


	//Buttons / links
	if (state.data.links !== null || state.data.links !== undefined) {
		//Submit button (Create Password)
		if (state.data.links['urn:vodafoneid:submit']) {
			buttons.push(
				<div className="btn-wrapper" key="btn1">
					{/* <Button
              type={buttonType}
              label={text(''+ state.data.context +'.button.label')}
              id="continueButton"
              handleClick={handleSubmit}
            /> */}
					<button
						className={`button ${buttonType}`}
						id="continueButton"
						type="button"
						onClick={ (event) => { trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text( state.data.context + '.button.label') }) ,handleSubmit(event)}}
						onMouseEnter={onMouseEnterHandler}
						onMouseLeave={onMouseLeaveHandler}
					>
						<span className="button_label">{text( state.data.context.toLowerCase() + '.button.label')}</span>
						<i className="spinner"></i>
					</button>
				</div>
			);
		}

		//Back button
		if (state.data.links['urn:vodafoneid:back']) {
			buttons.push(
				<div className="btn-wrapper" key="btn2">
					<Button
						type='secondary'
						label={text('back.button.label')}
						id="backButton"
						handleClick={() => { trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('back.button.label') }), handleBack()}}
					/>
				</div>
			);
		}

		//Password input tag
		passwordTag = <Input type={passwordType} inputClassName={'no-bottom-margin'} className={errors.pwdClass} label={text("label.for.password")} titleClassName={titleClassName} paddingClassName={paddingClassName} name="password" id="password" minLength={10} placeholder={text("placeholder.for.password")}
			handleChange={handleChange}
			handleBlur={handleBlur}
			handleKey={handleKeyPress}
			value={values.password}
			showIcon={true}
			iconClick={handleIconClick}
			iconClass={passwordIcon}
			errMsg={errors.pwdMsg}
		></Input>

		//Confirm Password input tag
		confirmPrdTag = <Input type={confirmPwdType} className={errors.confirmPwdClass} label={text("label.for.confirm_password")} titleClassName={confirmPwdTitle} paddingClassName={confirmPwdPadding} name="confirmPwd" id="confirmPwd" minLength={10} placeholder={text("label.for.confirm_password")}
			handleChange={handleChange}
			handleBlur={handleBlur}
			handleKey={handleKeyPress}
			value={values.confirmPwd}
			showIcon={true}
			iconClick={handleIconClick}
			iconClass={confirmPwdIcon}
			errMsg={errors.confirmPwdMsg}
		></Input>

		//Form the Password Rules
		pwdRules = <div className="rulesIcon"><div>{text('password.invalid.help.text.part.1')}</div>
			<p className={upperCase}>{text('password.invalid.help.text.part.2')}</p>
			<p className={lowerCase}>{text('password.invalid.help.text.part.3')}</p>
			<p className={numericCase}>{text('password.invalid.help.text.part.4')}</p>
			<p className={specialCase}>{text('password.invalid.help.text.part.5')}</p>
		</div>;

		//Pin Fields
		if (state.data.fields !== undefined && state.data.fields.pin !== undefined) {

			if (state.data.identifier) {
				let country = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
				market = countryMarket(state.data.nv, country);
				let identifier = state.data.identifier.value;

				if (market !== undefined) {
					identifier = identifier.replace(market.msisdnPrefix, market.msisdnPrefix + ' ');
				}
				//Identifier display section
				if (state.data.identifier.value !== undefined) {
					identifierDisplay = <div className="otp-info">
						<div className="section-form-label">{subTitle}</div>
						<div className="section-form-label notyoulink">
							+ {identifier} &nbsp;
							<a id="notyoulink" onClick={() => {trackPageEvent(state.data.context, {eventCategory: "link", eventLabel: text('edit.link.text') }), handleEdit()}}>{text('edit.link.text')}</a>
						</div>
					</div>
				}
			}

			confirmPin = <div className={`${errors.otpClass}`}>
				<div className="section-otp-input ">
					<InputOTP id="vf-input-otp" label={text('label.for.pin')} size={6} secured={true} otp={OTP} tabIndex={2} onHandleChange={(otp) => handleOTPChange(otp)} />
				</div>
			</div>

			if (state.data.links['urn:vodafoneid:resendPin']) {
				let logInfo = {
					event_desc: 'Click on - Resend Pin',
					event_msg: 'URL: ' + state.data.links['urn:vodafoneid:resendPin'].href,
					resp_code: '',
					page: state.data.context,
					fragment: state.data.fragment !== undefined ? state.data.fragment : '',
					market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
					service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
				};
				//Resend Pin link
				resendPin = <Link id="resend" handleLink={() => { trackPageEvent(state.data.context, {eventCategory: "link", eventLabel: text('not.received.pin.resend.label') }),  
				ServiceRequest(state.data.links['urn:vodafoneid:resendPin'], null, props.RequestCallBack, null , logInfo)}} className={'text-center resend-link'} label={text('not.received.pin.resend.label')}></Link>
			}
		}
	}

	//Password Show/Hide Icon Click
	function handleIconClick(event) {
		let desc = '', icon = '';
		if (event.target.id === 'passwordIcon') {
			setPasswordIcon(event.target.className.indexOf('show') !== -1 ? 'hide' : 'show');
			setPasswordType(event.target.className.indexOf('show') !== -1 ? 'text' : 'password');
			desc = 'password icon';
		}
		if (event.target.id === 'confirmPwdIcon') {
			setConfirmPwdIcon(event.target.className.indexOf('show') !== -1 ? 'hide' : 'show');
			setConfirmPwdType(event.target.className.indexOf('show') !== -1 ? 'text' : 'password');
			desc = 'confirm password icon';
		}
		icon = event.target.className.indexOf('show') !== -1 ? 'show ' : 'hide ';

		//Logger for Show/Hide Password
		VFLogger.client({
			event_desc: 'Click on ' + icon + desc,
			event_msg: '',
			resp_code: '',
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		});
	}

	//on Key Press Handler for Key Press on MSISDN input field
	function handleKeyPress(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		if (charCode !== 13 && charCode !== 32) {
			return true;
		} else if (charCode == 13) {
			handleSubmit(event);
		}
	}

	//Form Back function
	function handleBack() {
		let logInfo = {
			event_desc: 'Click on - Back button',
			event_msg: 'URL: ' + state.data.links['urn:vodafoneid:back'].href,
			resp_code: '',
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		};
		ServiceRequest(state.data.links['urn:vodafoneid:back'], '', props.RequestCallBack, null, logInfo);
	}

	//Handle Edit function
	function handleEdit() {
		let logInfo = {
			event_desc: 'Click on - Edit Identifier',
			event_msg: 'URL: ' + state.data.links['urn:vodafoneid:changeidentifier'].href,
			resp_code: '',
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		};
		ServiceRequest(state.data.links['urn:vodafoneid:changeidentifier'], '', props.RequestCallBack, null, logInfo);
	}

	//Form Submit function
	function submitForm() {

		console.log("Click on Submit");
		setButtonType("primary spinner disabled");

		let submitLink = state.data.links['urn:vodafoneid:submit'];

		const payload = createJSON(state.data.fields);
		//Check OTP
		if (state.data.fields !== undefined && state.data.fields.pin !== undefined) {
			payload["pin"] = OTP;
		}

		let logInfo = {
			event_desc: 'Click on - Create Password',
			event_msg: 'URL: ' + submitLink.href,
			resp_code: '',
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		};
		ServiceRequest(submitLink, JSON.stringify(payload), props.RequestCallBack, null, logInfo);
	}

	function createJSON(fields) {

		var passwordFields = ["password"];
		var jsonObj = {};

		for (var field in fields) {
			if (typeof (fields[field]) === "object") {
				if (Array.isArray(fields[field])) {
					jsonObj[field] = {};

					fields[field].forEach(function (item, index) {
						// Encrypt password
						if (passwordFields.indexOf(item.name) > -1) {
							let val = values.password;
							// Trim value
							val = val.trim();
							if (item.crypto) {
								var appendix = item.crypto.appendix;
								var exponent = item.crypto.exponent;
								var modulus = item.crypto.modulus;

								val = window.encryptPassword(val, modulus, exponent, appendix);

								jsonObj[field][item.name] = val;
							}
						} else {
							jsonObj[field][item.name] = item.value;
						}
					});

				}
			}
		}

		return jsonObj;

	}

	return (
		<>
			{/* {state.errorObj && state.errorObj.msg && (<FormErrorBox messageClass="form-error" error={text(state.errorObj.msg)} iconType={`icon_${state.errorObj.type}`} messageType={`message_${state.errorObj.type}`} />)} */}
			<div className="section-form">
				<div className="box">
					{errorsForCTA.errorMsgCTA && (<FormErrorBox error={errorsForCTA.errorMsgCTA} iconType="icon_error" messageType="message_error" />)}
					{identifierDisplay}
					{/* {errorsForCTA.pwdMsgCTA === '' && errorsForCTA.confirmMsgCTA  && (<FormErrorBox error={errorsForCTA.confirmMsgCTA} iconType="icon_error" messageType="message_error" />)} */}
					{confirmPin}
					{resendPin}
					{passwordTag}
					{pwdRules}
					{confirmPrdTag}
				</div>
				{buttons}
			</div>
		</>
	);
}

export default Registration;