import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import FormErrorBox from '../FormErrorBox/FormErrorBox';
import {text} from "../../utils/global";
import './Radio.scss';
import {ServiceRequest} from '../../utils/Services';
import RadioOptions from "./RadioOptions";
import analyticsTealium from "../../utils/Analytics/analytics";

const UpdateProfile = props => {
    let { state } = useContext(StoreContext);
    let buttons = [], radioList = [];
    const { trackPageEvent } = analyticsTealium();
    let identifier = state.data.identifier.type === 'tel' ? '+' + state.data.identifier.value : state.data.identifier.value;
    const [buttonType, setButtonType] = useState('primary');
    const [originalValue, setOriginalValue] = useState();
    const [value, setValue] = useState(null);
    //Radio Options
    let radioOptions = {};
     //DOM load event
     useEffect(() => {
        //Adding radio checked data
        if(state.data.fields !== undefined){
            radioOptions = state.data.fields.user_details[0];
            for( var option in radioOptions.options ){
                let msisdn = radioOptions.options[option]
                if(msisdn.value === true || msisdn.value === "true"){
                    setValue(msisdn.label);
                    setOriginalValue(msisdn.label);
                }
            }
        }
      }, []);

     //Buttons / links
     if(state.data.links !== null || state.data.links !== undefined){
        //Submit button (Continue)
        if(state.data.links['urn:vodafoneid:submit']){
          buttons.push(
            <div className="btn-wrapper" key="btn1">
                  <Button
                    type= {buttonType}
                    label={text('submit.button.label')}
                    id="continueButton"
                    handleClick={(event) => { trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('submit.button.label') }) , submitForm(event)}}
                  />
            </div>
          );
        }

        radioOptions = state.data.fields.user_details[0];
        for( var option in radioOptions.options ){
            let label;

            let radioOption = radioOptions.options[option];
            if( radioOption.label ){
                label = radioOption.label;
            }else if( radioOption.labelKey ){
                label = text(radioOption.labelKey );
            }

            radioList.push(<RadioOptions id={radioOption.label}
                            key={radioOption.label}
                            name={radioOptions.name}
                            value={radioOption.value}
                            checked={value === label}
                            label={'+' + label}
                            bold={radioOption.bold}
                            action={() => setValue(label)}
                            />);
        }
      }

    function submitForm () {
        console.log("Click on Submit");
        setButtonType("primary spinner disabled");
        let submitLink = state.data.links['urn:vodafoneid:submit'];
        let jsonObj = {
            user_details:{
                phone_number: originalValue === value
            }
        }
        const payload = JSON.stringify(jsonObj);
        ServiceRequest(submitLink,payload,props.RequestCallBack);
    }

    return (
        <>
          {/* {state.errorObj && state.errorObj.msg && (<FormErrorBox messageClass="form-error" error={text(state.errorObj.msg)} iconType="icon_error" messageType="message_error" />)} */}
          <div className="section-form">
              <div className="box">
              <div className="user-name">
                  <div className="section-form-label">{text('label.for.identifier.email')}</div>
                  <div className="section-form-label notyoulink">
                      {identifier}
                  </div>
              </div>
              <div>{text('label.for.identifier.msisdn')}</div>
              {radioList}
              </div>
              {buttons}
          </div>
        </>
    );
}

export default UpdateProfile;