import React, {
	useEffect,
	useContext,
	useState
} from "react";

import {
    StoreContext
} from "../../store/store";

// Action types
import {
	POPUP
} from "../../constants/actionTypes";

import ReactDOM from "react-dom";

// Stying
import "./pop-up.scss";

const PopUpContent = props => {
    const globalState = useContext(StoreContext);
	const { dispatch } = globalState;

	return (
        <div className="vf-popup-content-wrapper">
            <div className="vf-popup-content">
                {props.children}
            </div>
        </div>
    )
}

export default PopUpContent;
