import React from "react";
import "./Opco.scss"
const Opco = props => {
  return (
    <div className="opco-wrapper">
        <div className="flag-select ">
            <div className="selected-flag" >
                <span className="country-flag">
                  <span className={`flag_wrapper flag_sprite ${props.countryCode}_flag_65x34`}></span>
                  <span className="flag-prefix">{props.msisdnPrefix}</span>
                </span>
            </div>
        </div>
    </div>
  );
};

export default Opco;
