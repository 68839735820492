import { getUrl, isLocalStorage } from "../../utils/utils";

// TODO: Move cookies to config
export const useSMAPI = (props) => {

  window.getAuthId = function () {
    let authID = "NA";
    let urlHref = window.location.href.toString();

    if (
      window.location.hash.includes("state=") ||
      window.location.search.includes("state=")
    ) {
      let urlObj = getUrl();
      authID = decodeURIComponent(urlObj.params.state);
    } else {
      let trxId = urlHref.split("trx/")[1];
      if (trxId !== undefined && trxId.split("/").length > 0) {
        authID = trxId.split("/")[0] ? trxId.split("/")[0] : "NA";
      }
    }
    return authID;
  };

  const loadSMAPI = () => {
    return new Promise((resolve, reject) => {
      const SMAPILibrary = document.createElement("script");
      SMAPILibrary.src = process.env.PUBLIC_URL + "/sec.min.js?v=8.1.0";
      SMAPILibrary.async = true;
      SMAPILibrary.onload = () => {
        resolve("SMAPI loaded successfully");
      };

      SMAPILibrary.onerror = (error) => {
        if (process.env.REACT_APP_DEBUG_MODE) {
          console.error("failed to load SMAPI");
          console.log(error);
        }

        reject("Failed to load SMAPI");
      };

      document.head.appendChild(SMAPILibrary);
    });
  };

  // SMAPI
  const initiateSmapi = (SMAPIcookiesEnabled) => {
    console.log("");
    console.log("——— SMAPI ————————————");
    console.log("SMAPI loaded");
    console.log("SMAPI version: 8.1.0");
    console.log("");

    let headers = {
      "x-vf-trace-source": "js:com.vodafone.idpfe",
      "x-vf-trace-source-version":
        "SMAPI Version: 8.1.0 :: SMAPI ENV: " +
        process.env.REACT_APP_SMAPI_ENV +
        " :: FE Version: " +
        process.env.REACT_APP_VERSION +
        " :: ENV: " +
        process.env.REACT_APP_ENV,
      "x-vf-custom-authorization-id": window.getAuthId()
    };

    if (navigator.cookieEnabled && window.config.smapiEnabled) {
      try {
        if (process.env.REACT_APP_DEBUG_MODE) {
          console.log("SMAPI enabled");
          console.log("SMAPI cookiesEnabled: ", SMAPIcookiesEnabled);
        }
        window.submitter = sec.init({
          enabled: SMAPIcookiesEnabled,
          environment: process.env.REACT_APP_SMAPI_ENV,
          verbose: true,
          cookiesEnabled: true,
          networkMonitoringEnabled: false,
          spaTrackingOptions: {
            enabled: false,
            replaceState: false
          },
          headers
        });
        window.submitter.turnOnOff(SMAPIcookiesEnabled);
      } catch (e) {
        if (process.env.REACT_APP_DEBUG_MODE) {
          console.log(e);
        }
      }
    } else {
      if (process.env.REACT_APP_DEBUG_MODE) {
        console.log("SMAPI disabled");
      }
    }
  };

  const handleSMAPI = () => {
    let SMAPIcookieEnabled = true;

    // iOS MVA
    if (isLocalStorage() === true) {
      if (localStorage.getItem("trackStatus") === "Rejected") {
        SMAPIcookieEnabled = false;
      } else if (localStorage.getItem("trackStatus") === "Accepted") {
        SMAPIcookieEnabled = true;
      }
    }
    initiateSmapi(SMAPIcookieEnabled);
  };

  const toggleSMAPI = (SMAPIcookieEnabled) => {
    if (SMAPIcookieEnabled && window.config.smapiEnabled) {
      loadSMAPI().then(() => {
        // Clear session storage to allow reinitiate SMAPI
        sessionStorage.removeItem("smapi_init");
        initiateSmapi(SMAPIcookieEnabled);
      });
    }
    else{
      initiateSmapi(false);
    }
  };

  return {
    loadSMAPI,
    handleSMAPI,
    toggleSMAPI
  };
};

export default useSMAPI;
