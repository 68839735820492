import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../store/store';
import { text } from '../../utils/global';

import Input from '../Input/Input';
import Button from '../Button/Button';

import { ServiceRequest } from '../../utils/Services';
import analyticsTealium from "../../utils/Analytics/analytics";

import { RESPONSE_SUCCESS, RESPONSE_ERROR } from '../../constants/actionTypes';

const SelfcareKYC = (props) => {
  const { state, dispatch } = useContext(StoreContext);

  const [phoneNumber, setPhoneNumber] = useState('');

  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const [fields, setFields] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const { trackPageEvent } = analyticsTealium();

  useEffect(() => {
    if (state.data && state.data.fields && state.data.fields['user_details']) {
      for (let field of state.data.fields['user_details']) {
        if (field['name'] === 'phone_number') {
        }

        // country
        if (field['name'] === 'address.country') {
          setCountry(field['value']);

          for (let option of field['options']) {
            if (field['value'] === option['value']) {
              setCountryCode(option['code']);
            }
          }
        }

        setFields((previousFields) => ({
          ...previousFields,
          [field['name']]: field['value'] ? field['value'] : '',
        }));
      }
    }
  }, []);

  useEffect(() => {
    validateFields();
  }, [fields]);

  const validateFields = () => {
    console.log('--- fields ---');
    console.log(fields);

    let allowSubmit = true;

    for (let field in fields) {
      if (fields[field] == '') {
        allowSubmit = false;
      }
    }

    setAllowSubmit(allowSubmit);
  };

  const updateFields = (e) => {
    console.log(e.target.name + ': ' + e.target.value);

    setAllowSubmit(true);

    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFields((previousFields) => ({
      ...previousFields,
      [fieldName]: fieldValue,
    }));
  };

  const submitKYC = (e) => {
    console.log('submitKYC');

    e.preventDefault();

    let allowSubmit = true;

    let userDetails = {};
    userDetails['user_details'] = fields;

    const submitKYCLink = state.data.links['urn:vodafoneid:submit'];
    const payload = JSON.stringify(userDetails);
    const options = {
      headers: {
        Accept: submitKYCLink.type,
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-TOKEN': submitKYCLink.headers['X-CSRF-TOKEN'],
      },
    };

    console.log('--- request ---');
    console.log(submitKYCLink.href);

    // axios.defaults.withCredentials = true;
    // axios.put(submitKYCLink.href, payload, {withCredentials: true, options})
    // 	.then(response => {
    // 		console.log("--- response ---");
    // 		console.log(response);

    // 		dispatch({
    // 			type: RESPONSE_SUCCESS,
    // 			payload: response.data
    // 		});

    // 	})
    // 	.catch(error => {
    // 		console.log("--- error ---");
    // 	});

    let logInfo = {
      event_desc: 'Click on - Continue Button',
      event_msg: 'URL: ' + state.data.links['urn:vodafoneid:submit'].href,
    };

    ServiceRequest(submitKYCLink, payload, props.RequestCallBack, null, logInfo);
  };

  return (
    <>
      {state.data && state.data.identifier && (
        <div className="section-profile">
          <div className="section-profile-avatar"></div>
          <>{state.data.identifier.value}</>
        </div>
      )}

      <div className="section-form">
        <div className="validate-form">
          <div className={`box`}>
            {state.data && state.data.fields && state.data && state.data.fields.user_details && (
              <>
                {state.data.fields.user_details.map((item, i) => (
                  <div key={i}>
                    {item['type'] === 'select' ? (
                      <>
                        <div className="dropdown-wrap">
                          <div className="standalone-field-label">{text('label.for.' + item['name'])}</div>

                          {/* select */}
                          <select
                            className="dropdown"
                            name={item['name']}
                            id={item['name']}
                            value={fields[item['name']]}
                            disabled={item['disabled']}
                            onChange={(e) => updateFields(e)}
                          >
                            <option disabled={true} value="">
                              {text('label.for.' + item['name'])}
                            </option>

                            {/* option */}
                            {item['options'].map((option, i) => (
                              <option key={option['value']} value={option['value']}>
                                {text(option['labelKey'])}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : (
                      <>
                        <Input
                          type={item['type']}
                          label={text('label.for.' + item['name'])}
                          placeholder={text('label.for.' + item['name'])}
                          name={item['name']}
                          id={item['name']}
                          value={fields[item['name']]}
                          disabled={item['disabled']}
                          showIcon={false}
                          handleChange={(e) => updateFields(e)}
                        />
                      </>
                    )}
                  </div>
                ))}

                {allowSubmit ? (
                  <Button
                    type="primary"
                    label={text('submit.button.label')}
                    id="submitkButton"
                    handleClick={(event) => { trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('submit.button.label') }) ,submitKYC(event)}}
                  />
                ) : (
                  <Button type="primary disabled" label={text('submit.button.label')} id="submitkButton" />
                )}

                <Button type="secondary" label={text('back.button.label')} id="backButton" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelfcareKYC;
