import React, { useEffect, useContext, useRef } from 'react';
import { StoreContext } from '../../store/store';

// Action types
import { BANNER } from '../../constants/actionTypes';

import PopUp from '../PopUp/PopUp';
import PopUpHeader from '../PopUp/PopUpHeader';
import PopUpContent from '../PopUp/PopUpContent';
import PopUpFooter from '../PopUp/PopUpFooter';
import Text from '../Text/Text';
import Accordion from '../Accordion/Accordion';

const PrivacyPolicy = () => {
  const globalState = useContext(StoreContext);
  const { dispatch } = globalState;
  const policy = window.privacyPolicy.terms;
  const scrollTargetRef = useRef(null);

  useEffect(() => {
    if (globalState.state.popup === 'COOKIE_POLICY') {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return () => {};
  }, []);

  const closePopUp = () => {
    dispatch({
      type: BANNER,
      banner: 'COOKIE',
    });
  };

  return (
    <PopUp onClickOutSide={closePopUp}>
      <PopUpHeader onCloseButtonClick={closePopUp}>
        {window.privacyPolicy.header.title} {window.privacyPolicy.header.desc}
      </PopUpHeader>
      <PopUpContent>
        <Text align={'left'}>
          <p dangerouslySetInnerHTML={{ __html: window.privacyPolicy.header.descDetail }}></p>
        </Text>

        {policy.map((policyItem, i) => {
          return (
            <div key={'div_' + i} ref={scrollTargetRef}>
              <Accordion
                key={i}
                title={<span dangerouslySetInnerHTML={{ __html: policyItem.title }}></span>}
                open={globalState.state.popup === 'COOKIE_POLICY' && i == policy.length - 1}
              >
                <p dangerouslySetInnerHTML={{ __html: policyItem.desc }}></p>
              </Accordion>
            </div>
          );
        })}
      </PopUpContent>
      <PopUpFooter>
        <span dangerouslySetInnerHTML={{ __html: window.privacyPolicy.dt }}></span>
      </PopUpFooter>
    </PopUp>
  );
};

export default PrivacyPolicy;
