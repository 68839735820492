import React, { useContext, useState } from "react";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import SectionTitle from '../SectionTitle/SectionTitle';
import {text} from '../../utils/global';
import {ServiceRequest} from '../../utils/Services';
import analyticsTealium from "../../utils/Analytics/analytics";

const NonVodafoneEnabled = props => {

  let vodafoneBtn , nonVodafoneBtn, backBtn =  false;
  let vodafoneButtonLabel = false;
  const [vodafoneBtnType, setVodafoneBtnType] = useState('primary');
  const [nonVodafoneBtnType, setNonVodafoneBtnType] = useState('primary');
  const { trackPageEvent } = analyticsTealium();

  let { state } = useContext(StoreContext);
  //Buttons / links
  if(state.data.links !== null || state.data.links !== undefined){

    //Vodafone button
    if(state.data.links['urn:vodafoneid:vodafone']){
     vodafoneButtonLabel = text("introduction.to.non_vodafone_enabled.page") + '<br/>' + text("vodafone.button.intro.text");
     vodafoneBtn = <div className="btn-wrapper" key="btn1"><Button id="vodafone_button"
                               label={text("vodafone.button.label")}
                               type= {vodafoneBtnType}
                               handleClick={(event) => {trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('vodafone.button.label') }), handleClick(event) }}
                        /></div>;
    }

    // Non Vodafone
    if( state.data.links["urn:vodafoneid:nonVodafone"] ){
      nonVodafoneBtn = <div className="btn-wrapper" key="btn2"><Button id="non_vodafone_button"
                            label={text("non.vodafone.button.label")}
                            type={nonVodafoneBtnType}
                            handleClick={(event) => {trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('non.vodafone.button.label') }), handleClick(event) }}
                  /></div>;
    }
    //Back Button
    if(state.data.links['urn:vodafoneid:back']){
      backBtn = <div className="btn-wrapper" key="btn3">
              <Button
                type='secondary'
                label={text('back.button.label')}
                id="backButton"
                handleClick={() => {trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('back.button.label') }), ServiceRequest(state.data.links['urn:vodafoneid:back'],null,props.RequestCallBack)}}
              />
            </div>;
    }
  }

  //Button Click
  function handleClick (event){
    if(event.target.id === 'non_vodafone_button'){
      setNonVodafoneBtnType('primary spinner disabled');
      ServiceRequest(state.data.links['urn:vodafoneid:nonVodafone'],null,props.RequestCallBack)
    }else{
      //setVodafoneBtnType('primary spinner disabled');
      ServiceRequest(state.data.links['urn:vodafoneid:vodafone'],null,props.RequestCallBack)
    }
  }

    return (
      <>
        <div className="section-form-panel">
            <div className="section-form">
              <div className="validate-form">
              <div className="box">
                  <SectionTitle subTitle={vodafoneButtonLabel}></SectionTitle>
                  {vodafoneBtn}
                  <SectionTitle subTitle={text("non.vodafone.button.intro.text")}></SectionTitle>
                  {nonVodafoneBtn}
                  {backBtn}
              </div>
              </div>
            </div>
        </div>
      </>
    );
}

export default NonVodafoneEnabled;