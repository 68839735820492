import { useState, useEffect, useContext } from 'react';
import {text} from "../../utils/global";
import { StoreContext } from '../../store/store';

import {
    RESPONSE_ERROR_CLEAR

} from "../../constants/actionTypes";

const useForm = (callback) => {
    const [values, setValue] = useState({password:""});
    const [errors, setErrors] = useState({msg:"", msgClass:""});
    const [buttonType, setButtonType] = useState("primary disabled");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [titleClassName, setTitleClassName] = useState("hidden");
    const [paddingClassName, setPaddingClassName] = useState("paddingTop20");
    let { state, dispatch } = useContext(StoreContext);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setIsSubmitting(true);
        if(state.data.links['urn:vodafoneid:submitIdentifier']){
            callback();
        }else if(isValidForm()){
            callback();
        }
        else{
            console.log("Error while submitting the form");
        }
        event.target.blur();
    };

    const handleChange = (event) => {
        event.persist();
        setValue(values => ({ ...values, [event.target.name]: event.target.value }));
        if(event.target.value.length > 0 ){
            if(state.errorObj && state.errorObj.msg){
                dispatch({
                    type: RESPONSE_ERROR_CLEAR,
                    payload: {}
                });
            }
          setButtonType("primary");
          setTitleClassName("");
          setPaddingClassName("");
          setIsSubmitting(false);
        }
        else{
          setButtonType("primary disabled");
          setTitleClassName("hidden");
          setPaddingClassName("paddingTop20");
        }
    };

    const resetValues = () => {
        setValue(values => ({ ...values, password : ''}));
        setButtonType("primary disabled");
        setTitleClassName("hidden");
        setPaddingClassName("paddingTop20");
        setIsSubmitting(false);
        setErrors(errors => ({ ...errors, 'msg': '', 'msgClass': '' }));
    }

    const handleBlur = (event) => {
        validateForm();
    };

    function validateForm(){
        let minLength = process.env.REACT_APP_MIN_PASSWORD_LENGHT;
        if(!isValidForm()){
            setButtonType("primary disabled");
            setErrors(errors => ({ ...errors, 'msg': text('validation.error.min.length.message.password', minLength), 'msgClass' : 'inline_error' }));
        }else{
            setErrors(errors => ({ ...errors, 'msg': '', 'msgClass': '' }));
        }
    }

    function isValidForm(){
        let minLength = process.env.REACT_APP_MIN_PASSWORD_LENGHT;
        if(values.password){
            if(minLength !== -1 && values.password.length < minLength){
                return false;
            }
            return true;
        }
        return false;
    }


    return {
        errors,
        values,
        titleClassName,
        setTitleClassName,
        paddingClassName,
        setPaddingClassName,
        buttonType,
        setButtonType,
        handleSubmit,
        handleChange,
        resetValues,
        handleBlur
    }
}


export default useForm;