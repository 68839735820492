import React from "react";
import './Checkbox.scss';

const Checkbox = props => {
  return (
    <div className="remember-wrapper">
        <label className="checkBoxOuter">{props.label}
        <input type="checkbox" name={props.id} id={props.id} checked={props.checked}
        onChange={(event) => props.handleCheckbox(event)}
        disabled={props.disabled} />
            <span className="checkmark"></span>
        </label>
    </div>
  );
};

export default Checkbox;