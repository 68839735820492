import React, {
	useEffect,
	useContext,
	useState
} from "react";

// Stying
import "./toggle.scss";

const Toggle = props => {
    const [checked, setChecked] = useState(props.checked ? props.checked : false);

	useEffect(() => {
		return () => {
		};

	}, []);

    const handleClick = () => {
        setChecked(checked ? false : true);
		props.onHandleClick();
    }

	return (
        <div className="vf-toggle-wrapper">
            <div className={"vf-toggle" + (checked ? " on": " off")} onClick={handleClick}>
                <div className="vf-toggle-label">
                    {checked ?
                        <>
                            {props.labelOn}
                        </>
                    :
                        <>
                            {props.labelOff}
                        </>
                    }
                </div>
            </div>
        </div>
	);
}

export default Toggle;
