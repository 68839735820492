import { useState, useEffect, useContext } from 'react';
import {text} from "../../utils/global";
import { StoreContext } from '../../store/store';
import Parser from 'html-react-parser';
import { $ }  from 'react-jquery-plugin'

import {
    RESPONSE_ERROR_CLEAR

} from "../../constants/actionTypes";

const useForm = (callback) => {
    const [values, setValue] = useState({password:"",confirmPwd:""});
    const [errors, setErrors] = useState({pwdMsg:"", confirmPwdMsg:"",confirmPwdClass:"",pwdClass:""});
    const [errorsForCTA, setErrorsForCTA] = useState({errorMsgCTA:""});
    const [buttonType, setButtonType] = useState("primary");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [titleClassName, setTitleClassName] = useState("hidden");
    const [paddingClassName, setPaddingClassName] = useState("paddingTop20");
    const [confirmPwdTitle, setConfirmPwdTitle] = useState("hidden");
    const [confirmPwdPadding, setConfirmPwdPadding] = useState("paddingTop20");
    const [OTP, setOTP] = useState("");
    //Password Strength
    const [upperCase, setUpperCase] = useState("greyTick");
    const [lowerCase, setLowerCase] = useState("greyTick");
    const [numericCase, setNumericCase] = useState("greyTick");
    const [specialCase, setSpecialCase] = useState("greyTick");
    const [eightcharacter, setEgithCharacter] = useState("greyTick");

    const[validation, setValidation] = useState(true);

    let { state, dispatch } = useContext(StoreContext);

    useEffect(() => {
        if (isSubmitting) {
            let index = 1;
            for (const [key, value] of Object.entries(errorsForCTA)) {
                if(value.length === 0 && Object.keys(errorsForCTA).length === index){
                    if(errors.pwdMsg === '' && errors.confirmPwdMsg === ''){
                        callback();
                    }
                }else if(value.length > 0){
                    setIsSubmitting(false);
                    console.log("Setting submitting call " + isSubmitting );
                }
                index++;
            }
        }
    }, [errorsForCTA]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        validateCTA ();
        setIsSubmitting(true);
        event.target.blur();
    };

    function validateCTA(){
        console.log("Form validation is called");
        //Check Password error msg is displayed
        let regEx = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
        let str = text('validation.error.message.review');
        let str_pin = '' , str_pwd = '' , str_cnf = '';
        //Check OTP
        if(state.data.fields !== undefined && state.data.fields.pin !== undefined){
            if(OTP.length < 6){
                str_pin = convertToLink(text('validation.error.message.'+ state.data.context +'.cta.pin.password'),['pin_0'],false);
            }else{
                str_pin = '';
            }
        }

        if( !regEx.test( values.password ) ){
            str_pwd = convertToLink(text('validation.error.message.'+ state.data.context +'.cta.new.password'),['password']);
        }
        else{
            str_pwd = '';
        }

        //Check Confirm password error msg is displayed
        if(values.confirmPwd === '' || (errors.confirmPwdMsg !== '')){
            str_cnf = convertToLink(text('validation.error.message.'+ state.data.context +'.cta.confirm.password'),['confirmPwd']);
        }else{
            str_cnf = '';
        }

        //Set the Error Message
        if(str_pin !== undefined && str_pin !== ''){
            str = str + '<br/>' + str_pin;
        }
        if(str_pwd !== undefined &&  str_pwd !== ''){
            str = str + '<br/>' + str_pwd;
        }
        if(str_cnf !== undefined && str_cnf !== ''){
            str = str + '<br/>' + str_cnf;
        }

        //Set blank error message
        if(str_pin === '' && str_pwd === '' && str_cnf === ''){
            str = '';
        }

        if(str_pwd !== '' && values.password.length === 0){
            setErrors(errors => ({ ...errors, 'pwdMsg': text('validation.error.message.new.password.required') ,'pwdClass':'inline_error' }));
        }

        if(str_cnf !== '' && values.confirmPwd.length === 0){
            setErrors(errors => ({ ...errors, 'confirmPwdMsg': text('validation.error.message.confirm.password.required'),'confirmPwdClass':'inline_error' }));
        }

        if(str_pin !== '' && values.password.length === 0){
            setErrors(errors => ({ ...errors, 'otpClass':'inline_error' }));
        }else{
            setErrors(errors => ({ ...errors, 'otpClass':'' }));
        }
        if(str && str.length > 0){
            setErrorsForCTA(errorsForCTA => ({ ...errorsForCTA, 'errorMsgCTA': Parser(str) }));
        }else{
            setErrorsForCTA(errorsForCTA => ({ ...errorsForCTA, 'errorMsgCTA': "" }));
        }
    }

    function convertToLink (str, arg){
        if(str){
            for (var i = 0; i <= arg.length; i++) {
                let link = str.substring(str.indexOf('{') + 1 , str.indexOf('}')) ;
                let linkTag = '<div class="focusLink" data-addr='+ arg[i] +'  >'+ link +'</div>';
                link = '\\{' + link + '\\}';
                str = str.replace(new RegExp(link, 'g'), linkTag);
            }
        }
        return str;
    }

    //Link click in message display event
    $('.focusLink').click(function (evt){
        let ele = evt.target.dataset.addr;
        $('#' + ele).focus();
        evt.preventDefault();
    });

    const handleChange = (event) => {
        event.persist();
        setValue(values => ({ ...values, [event.target.name]: event.target.value }));
        if(event.target.value.length > 0 ){
            if(state.errorObj && state.errorObj.msg){
                dispatch({
                    type: RESPONSE_ERROR_CLEAR,
                    payload: {}
                });
            }

            if(event.target.id === 'confirmPwd'){
                setConfirmPwdTitle("");
                setConfirmPwdPadding("");
                //setErrors(errors => ({ ...errors, 'confirmPwdMsg': '' , 'confirmPwdClass': '' }));
            }else{
                measureStrength(event.target.value);
                setTitleClassName("");
                setPaddingClassName("");
                //setErrors(errors => ({ ...errors, 'pwdMsg': '' , 'pwdClass': ''}));
            }
            setIsSubmitting(false);
        }
        else{
            if(event.target.id === 'confirmPwd'){
                //setButtonType("primary disabled");
                setConfirmPwdTitle("hidden");
                setConfirmPwdPadding("paddingTop20");
            }else{
                measureStrength('');
                //setButtonType("primary disabled");
                setTitleClassName("hidden");
                setPaddingClassName("paddingTop20");
            }
        }
    };


    function measureStrength(value){
        let fieldValue = value;
        let numberOfCharacters = fieldValue.length;
        let uppercaseLetters = [];
        let requiredUppercaseLetters = [];
        let lowercaseLetters = [];
        let requiredLowercaseLetters = [];
        let numbers = "";
        let requiredNumbers = "";
        let symbols = "";

        var invalid = false;
        for (var i = 0; i < numberOfCharacters; i++) {
            if (/[a-z]/.test(fieldValue.charAt(i))) {
                // all lowercase letters
                requiredLowercaseLetters.push(fieldValue.charAt(i));
                // non consecutive lowercase letters
                if (!(/[a-z]/.test(fieldValue[i - 1]) || /[a-z]/.test(fieldValue[i + 1]))) {
                    lowercaseLetters.push(fieldValue.charAt(i));
                }
            } else if (/[A-Z]/.test(fieldValue.charAt(i))) {
                // all uppercase letters
                requiredUppercaseLetters.push(fieldValue.charAt(i));
                // non consecutive uppercase letters
                if (!(/[A-Z]/.test(fieldValue[i - 1]) || /[A-Z]/.test(fieldValue[i + 1]))) {
                    uppercaseLetters.push(fieldValue.charAt(i));
                }
            } else if (/[0-9]/.test(fieldValue.charAt(i))) {
                // all numbers
                requiredNumbers += fieldValue.charAt(i);
                // non consecutive numbers
                if (!(/[0-9]/.test(fieldValue[i - 1]) || /[0-9]/.test(fieldValue[i + 1]))) {
                    numbers += fieldValue.charAt(i);
                }
            } else {
                symbols += fieldValue.charAt(i);
            }
        }

        // -----------------------------------------------------------------------------------------
        // Requirement
        // -----------------------------------------------------------------------------------------

        if (fieldValue.length >= 8) {
            setEgithCharacter('greenTick');
        }else{
            setEgithCharacter('greyTick');
        }

        // uppercaseLettersValid

        if (requiredUppercaseLetters.length > 0) {
            setUpperCase('greenTick');
        }else{
            setUpperCase('greyTick');
        }

        // lowercaseLettersValid

        if (requiredLowercaseLetters.length > 0) {
            setLowerCase('greenTick');
        }else{
            setLowerCase('greyTick');
        }

        // numericDigitsValid

        if (requiredNumbers.length > 0) {
            setNumericCase('greenTick');
        }else{
            setNumericCase('greyTick');
        }

        // specialCharactersValid

        if (symbols.length > 0) {
            setSpecialCase('greenTick');
        }else{
            setSpecialCase('greyTick');
        }

        if(fieldValue.length === 0){
            setEgithCharacter('greyTick');
            setUpperCase('greyTick');
            setLowerCase('greyTick');
            setNumericCase('greyTick');
            setSpecialCase('greyTick');
        }
    }

    const resetValues = () => {
        setValue(values => ({ ...values, password : '' , confirmPwd : ''}));
        setButtonType("primary");
        setConfirmPwdTitle("hidden");
        setConfirmPwdPadding("paddingTop20");
        setTitleClassName("hidden");
        setPaddingClassName("paddingTop20");
        setIsSubmitting(false);
        setErrors(errors => ({ ...errors, 'pwdMsg': '' , 'pwdClass': ''}));
        setErrors(errors => ({ ...errors, 'confirmPwdMsg': '' , 'confirmPwdClass': ''}));
        setEgithCharacter('greyTick');
        setUpperCase('greyTick');
        setLowerCase('greyTick');
        setNumericCase('greyTick');
        setSpecialCase('greyTick');
    }

    const handleBlur = (event) => {
        if (event) event.preventDefault();
        if(validation === false){
            validatePassword(event.target.id);
            handleSubmit(event)
        }else{
            validatePassword(event.target.id);
        }
    };

    function validatePassword(id){
        // RegEx
        if(id === 'password'){
            let regEx = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
            if( !regEx.test( values.password ) ){
                if (window.config.showLogs) {
                    //console.log("Password not valid - password does not meet the requirements");
                    setErrors(errors => ({ ...errors, 'pwdMsg': text('validation.error.message.new.password'),'pwdClass':'inline_error' }));
                }
            }else{
                //Clear the Password Error msg
                setErrors(errors => ({ ...errors, 'pwdMsg': '' , 'pwdClass': ''}));
            }
        }
        if(values.confirmPwd !== '' && values.confirmPwd !== values.password){
            //console.log("Confirm password does not match");
            setErrors(errors => ({ ...errors, 'confirmPwdMsg': text('validation.error.message.confirm.password'),'confirmPwdClass':'inline_error' }));
        }else{
            setErrors(errors => ({ ...errors, 'confirmPwdMsg': '' , 'confirmPwdClass': ''}));
        }
    }

    function onMouseEnterHandler(event){
        setValidation(false);
    }

    function onMouseLeaveHandler(event){
        setValidation(true);
    }

    return {
        errors,
        errorsForCTA,
        values,
        OTP,
        setOTP,
        confirmPwdTitle,
        setConfirmPwdTitle,
        confirmPwdPadding,
        setConfirmPwdPadding,
        titleClassName,
        setTitleClassName,
        paddingClassName,
        setPaddingClassName,
        upperCase,
        setUpperCase,
        lowerCase,
        setLowerCase,
        numericCase,
        setNumericCase,
        specialCase,
        setSpecialCase,
        eightcharacter,
        setEgithCharacter,
        setErrors,
        buttonType,
        setButtonType,
        handleSubmit,
        handleChange,
        onMouseEnterHandler,
        onMouseLeaveHandler,
        resetValues,
        handleBlur
    }
}


export default useForm;