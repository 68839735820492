/**Action Types */

/**Initial */
export const INITIAL_START = "INITIAL_START";
export const INITIAL_SUCCESS = "INITIAL_SUCCESS";
export const INITIAL_ERROR = "INITIAL_ERROR";

/**Authorize */
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";
export const RESPONSE_ERROR = "RESPONSE_ERROR";
export const RESPONSE_ERROR_CLEAR = "RESPONSE_ERROR_CLEAR";

export const SETTING_TERMS = "SETTING_TERMS";
export const SETTING_PRIVACY = "SETTING_PRIVACY";
export const SPLASH_LOAD = "SPLASH_LOAD";
export const FIELD_LOAD = "FIELD_LOAD";
export const SETTING_COOKIE = "SETTING_COOKIE";
export const STORE_VALUE = "STORE_VALUE";

export const BANNER = "BANNER";
export const POPUP = "POPUP";