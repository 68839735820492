import React from "react";
import "./ConfirmPinSplash.scss";

export const ConfirmPinSplash = props => {
  return (
    <div className="confirm-splash">
      <div className="confirm-splash-icon"></div>
      <p className="confirm-splash-message">{props.message}</p>
    </div>
  );
};
export default ConfirmPinSplash;
