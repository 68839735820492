import React from "react";
import "./OrSeperate.scss";
import {text} from '../../utils/global';

const OrSeperate = props => {
  return (
    <div className = "or_separator_wrap">
        <span className= "or_separator_label">{text("or.label")}</span>
        <div className= "or_separator"></div>
    </div>
  );
};

export default OrSeperate;
