
import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../../store/store';
import Button from '../Button/Button';
import { text, countryMarket } from "../../utils/global";
import { ServiceRequest, handleLink } from '../../utils/Services';
import ConfirmPinSplash from '../ConfirmPinSplash/ConfirmPinSplash';
import InputOTP from '../InputOTP/InputOTP';
import analyticsTealium from "../../utils/Analytics/analytics";

const ConfirmPin = props => {
	let { state } = useContext(StoreContext);
	let buttons = [];
	let identifier = '', identifierLabel = '', resendLink = false;
	if (state.data.identifier !== undefined) {
		identifier = state.data.identifier.type === 'tel' ? '+' + state.data.identifier.value : state.data.identifier.value;
		identifierLabel = state.data.identifier.type === 'tel' ? 'msisdn' : state.data.identifier.type;
	}

	let identifierDisplay = false, pin = false;
	let market = undefined;
	//let errorMsg = '';
	let redirectTimeout = process.env.REACT_APP_REDIRECT_TIMEOUT;
	const [buttonType, setButtonType] = useState("primary disabled");
	const [redirectButtonType, setRedirectButtonType] = useState("primary disabled");
	const [OTP, setOTP] = useState("");
	const [otpClass, setOtpClass] = useState("section-otp");
	const [showConfirmPinSplash, setConfirmPinSplash] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [title, setTitle] = useState('page.title.for.confirm_pin.page');
	const [subTitle, setSubTitle] = useState(text('page.intro.for.confirm_pin.page'));
	const { trackPageEvent } = analyticsTealium();

	let logInfo = {
		//event_desc : 'Click on - Edit Identifier',
		event_msg: 'URL: ' + state.data.links['urn:vodafoneid:changeidentifier'].href,
		resp_code: '',
		page: state.data.context,
		fragment: state.data.fragment !== undefined ? state.data.fragment : '',
		market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
		service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
	};

	//DOM load event
	useEffect(() => {
		//On Error Clear the screen
		if (state.errorObj && state.errorObj.msg) {
			resetForm();
			setOtpClass('inline_error');
		} else {
			setOtpClass('section-otp');
		}

	}, [state.errorObj]);


	useEffect(() => {
		setTitle('page.title.for.confirm_pin.page');
		setSubTitle(text('page.intro.for.confirm_pin.page'))
		//CONFIRM PIN - set title for NV flow
		if (state.data.nv === true && state.data.identifier.type === 'email') {
			setSubTitle(text("page.intro.for.confirm_pin.page.nv"));
		}
	}, []);

	const handleOTPChange = (OTP) => {
		setOTP(OTP);

		if(OTP.length == 6){
			setButtonType("primary");
		}else{
			setButtonType("primary disabled");
		}
	}

	if (state.data.identifier) {
		let country = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
		market = countryMarket(state.data.nv, country);
		let identifier = state.data.identifier.value;

		if (market !== undefined && state.data.identifier.type === 'tel') {
			identifier = identifier.replace(market.msisdnPrefix, market.msisdnPrefix + ' ');
			identifier = '+' + identifier;
		}
		//Identifier display section
		if (state.data.identifier.value !== undefined) {
			identifierDisplay = <div className="otp-info">
				<div className="section-form-label">{subTitle}</div>
				<div className="section-form-label notyoulink">
					{identifier} &nbsp;
					<a id="notyoulink" onClick={() => {trackPageEvent(state.data.context, {eventCategory: "link", eventLabel: text('edit.link.text') }), handleEdit()}}>{text('edit.link.text')}</a>
				</div>
			</div>
		}
	}

	//Buttons / links
	if (state.data.links !== null || state.data.links !== undefined) {
		//Submit button (Continue)
		if (state.data.links['urn:vodafoneid:confirmPin']) {
			buttons.push(
				<div className="btn-wrapper" key="btn1">
					<Button
						type={buttonType}
						label={text('confirm_pin.button.label')}
						id="continueButton"
						handleClick={ () => {trackPageEvent(state.data.context, {eventCategory: "button", eventLabel: text('confirm_pin.button.label') }), submitForm()}}
					/>
				</div>
			);
		}

		//PIN tag
		if (state.data.fields !== undefined && state.data.fields.pin !== undefined) {
			pin = <div className={`${otpClass}`}>
				<div className="section-otp-input">
					<InputOTP id="vf-input-otp" label={text('label.for.pin')} size={6} secured={true} otp={OTP} tabIndex={2} onHandleChange={(otp) => handleOTPChange(otp)} />
				</div>
			</div>

			resendLink = <div className="section-otp-label">
				<a id="resendlink" onClick={() => { trackPageEvent(state.data.context, {eventCategory: "link", eventLabel: text('not.received.pin.resend.label') }), resendLinkHander() }}>{text('not.received.pin.resend.label')}</a>
			</div>
		}
	}

	//Handle Edit function
	function handleEdit() {
		logInfo["event_desc"] = "Click on - Edit Identifier";
		ServiceRequest(state.data.links['urn:vodafoneid:changeidentifier'], '', props.RequestCallBack, null, logInfo);
	}

	function redirectLinkHandler() {
		setRedirectButtonType("primary spinner disabled");
		handleLinks(state.data.links['urn:vodafoneid:redirect'], props.RequestCallBack, '', null, 'Click on - Redirect Me');
	}

	function resendLinkHander() {
		setOTP('');
		resetForm();
		if (state.data.nv === true && state.data.identifier.type === 'email') {
			setSubTitle(text("page.intro.for.confirm_pin.another.page.nv"));
		} else {
			setSubTitle(text("page.intro.for.confirm_pin.another.page"));
		}
		handleLinks(state.data.links['urn:vodafoneid:resendPin'], props.RequestCallBack, '', null, 'Click on - Resend PIN');
	}

	function resetForm() {
		setConfirmPinSplash(false);
		setOTP('');
		if(document.getElementById("otpInputWrap")){
			document.getElementById("otpInputWrap").firstElementChild.classList.remove("initial_state");
			document.getElementById("otpInputWrap").firstElementChild.firstElementChild.focus();
		}
		setButtonType('primary disabled');
	}

	//Link Handler
	function handleLinks(link, callBack, target, desc) {
		let logInfo = {
			event_desc: desc,
			event_msg: 'URL: ' + link.href,
			page: state.data.context,
			fragment: state.data.fragment !== undefined ? state.data.fragment : '',
			market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
			service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
		};
		handleLink(link, callBack, target, logInfo);
	}

	//Form Submit function
	function submitForm() {
		if (OTP.length > 1) {
			setButtonType("primary spinner disabled");
			setConfirmPinSplash(true);
			let submitLink = state.data.links['urn:vodafoneid:confirmPin'];
			identifier = state.data.identifier.value;
			let jsonObj = {
				identifier: identifier,
				identifierType: state.data.identifier.type
			}

			// PIN
			if (OTP.length > 1) {
				jsonObj["pin"] = OTP;
			}

			const payload = JSON.stringify(jsonObj);
			let logInfo = {
				event_desc: 'Continue Button Clicked',
				event_msg: 'URL: ' + submitLink.href,
				resp_code: '',
				page: state.data.context,
				fragment: state.data.fragment !== undefined ? state.data.fragment : '',
				market: state.data.nv !== undefined && state.data.nv === true ? 'NV' : market,
				service_name: state.data.context === undefined || state.data.context === '' ? '' : state.data.clientInfo === undefined || state.data.clientInfo === null ? '' : state.data.clientInfo.client_name
			};
			ServiceRequest(submitLink, payload, props.RequestCallBack, null, logInfo);
		}
	}

	return (
		<>
			{showConfirmPinSplash && <ConfirmPinSplash message={text('confirm.pin.message')}></ConfirmPinSplash>}
			<div className="section-form">
				<div className="box">
					{identifierDisplay}
					{pin}
				</div>
				{buttons}
				{resendLink}
			</div>
		</>
	);
}

export default ConfirmPin;