import { generateUUID , getTopDomainName } from "../utils";
import { setCookie,getCookieByName, deleteCookie } from "../global";

let _config;
let _active;

const analyticsTealium = () => {

    const initializeTealium = () => {
       if (!window.config.analytics.endpoint) {
            _active = false;
            console.error("Analytics: API config is not set. Service disabled");
        }else{
            //To Do: Set active according to marketing cookie
            _active = window.config.analytics && window.config.analytics.tealium_enabled ? true : false;
            let env = 'dev';
            if(process.env.REACT_APP_ENV === 'stagingref') {
                env = 'preprod';
            }
            else if(process.env.REACT_APP_ENV === 'prod') {
                env = 'prod';
            }
            const config = {
                commonEntries : {
                    tealium_account: window.config.analytics.tealium_account,
                    tealium_profile: window.config.analytics.tealium_profile,
                    tealium_datasource: window.config.analytics.tealium_datasource,
                    env: env,
                    page_country: window.config.analytics.page_country,
                    page_channel: "web",
                    page_platform: "IDP Web"
                },
                api : {
                    endpoint: window.config.analytics.endpoint
                }
            };
            _config = config;
            console.log("Analytics: service initialized");
        }
    }

    /**
 * Sets the active state of the analytics service.
 * Will not be set if analytics service was not previously initialized.
 * @param {boolean} active - Whether the analytics service is active.
 */
function setTealiumActive(active) {
    if (active) {
        if (!_config) {
            return console.warn("Analytics: Unable to set active state. Service was not initialized.");
        }

        if (!_config.commonEntries.tealium_account ||
            !_config.commonEntries.tealium_profile ||
            !_config.commonEntries.tealium_datasource) {
            return console.error("Analytics: Please check configuration values");
        }
    }

    _active = active;
}

    const fetchAbodeMarketVisitorID = async () => {
        let tealiumAdobeID = '';
        const tealiumMarketMidUrl = window.config.analytics.tealium_market_mid_url;
        if(tealiumMarketMidUrl){
            return new Promise(function (resolve, reject) {
                fetch(tealiumMarketMidUrl)
                            .then((response) => {
                                return response.json();
                             })
                             .then((data) => {
                                if(data.d_mid){
                                    tealiumAdobeID = data.d_mid;
                                    deleteCookie("s_ecid");
                                    setCookie("s_ecid", tealiumAdobeID, getTopDomainName() , 183 );
                                    resolve(tealiumAdobeID);
                                }else{
                                    console.warn("Invalid tealium abode market visitor ID url");
                                    resolve(tealiumAdobeID);
                                }
                             })
                             .catch((err) => {
                                console.warn('Tealium abode market visitor ID has not been generated');
                                resolve(tealiumAdobeID);
                             });
            });
        }else{
            console.warn("Tealium abode market visitor ID URL is missing in config");
            return tealiumAdobeID;
        }
    };

    const fetchAbodeGroupVisitorID = async () => {
         /* Set visitor_id_aecid_group (Adobe Group Visitor ID)*/
         let tealiumGroupAdobeID = '';
         const tealiumVodafoneGroupMidUrl = window.config.analytics.tealium_vodafone_group_mid_url;
         if(tealiumVodafoneGroupMidUrl){
             return new Promise(function (resolve, reject) {
                fetch(tealiumVodafoneGroupMidUrl)
                             .then((response) => {
                                    return response.json();
                                })
                              .then((data) => {
                                 if(data.d_mid){
                                     let tealiumGroupAdobeID = data.d_mid;
                                     deleteCookie("visitor_id_aecid_group");
                                     setCookie("visitor_id_aecid_group", tealiumGroupAdobeID, getTopDomainName() , 183);
                                     resolve(tealiumGroupAdobeID);
                                 }else{
                                     console.warn("Invalid tealium abode vodafone group visitor ID url");
                                     resolve(tealiumGroupAdobeID);
                                 }
                              })
                              .catch((err) => {
                                 console.warn(err);
                                 resolve(tealiumGroupAdobeID);
                              });
              });
         }else {
            console.warn("Tealium abode vodafone group URL is missing in config");
            return tealiumGroupAdobeID;
         }

     };

    /**
     * Sends a single event to the analytics endpoint.
     * @param {object} data - The event data to be sent.
     */
    const sendOneEvent = async (eventData) => {
        let data = eventData;
        if (! _config.api.endpoint) {
            return console.warn("Analytics endpoint not configured. Data not sent.");
        }

        // Utag data bridge if provided
        const utagDataBridge = window.utag_data_bridge;
        if (utagDataBridge && typeof utagDataBridge === "object") {
            data["has_utag_data_bridge"] = true;
            Object.assign(data,utagDataBridge, eventData);
        }

        /* Set tealium_visitor_id */
        if(!data['tealium_visitor_id']){

            //Check from query parameter
            const searchParams = new URLSearchParams(window.location.search);
            let tealiumVisitorID = searchParams.get('t_v_id');

            if(!tealiumVisitorID){
                //Check the cookie for tealium visitor ID
                tealiumVisitorID = getCookieByName('utag_main_v_id');
                if(!tealiumVisitorID){
                    tealiumVisitorID = generateUUID ();
                }
                deleteCookie('utag_main_v_id');
                setCookie('utag_main_v_id', tealiumVisitorID,getTopDomainName(), 183);
            }else {
                deleteCookie("utag_main_v_id");
                setCookie("utag_main_v_id", tealiumVisitorID, getTopDomainName() , 183);
                /* Remove t_v_id query parameter */
                if( history ){
                    const newURL = new URL(window.location.href);
                    newURL.searchParams.delete('t_v_id');
                    if( history.state ){
                       history.replaceState(history.state, history.context, newURL);
                    }else{
                       history.replaceState(null, '', newURL);
                    }
                }
            }
            data["tealium_visitor_id"] = tealiumVisitorID;
        }else if (getCookieByName('utag_main_v_id') != data['tealium_visitor_id'] ){
            deleteCookie("utag_main_v_id");
            setCookie("utag_main_v_id", data['tealium_visitor_id'], getTopDomainName() , 183 );
        }

        /* Set visitor_id_aecid (Adobe Visitor ID)*/
        if(!data['visitor_id_aecid']){

             //Check from query parameter
            const searchParams = new URLSearchParams(window.location.search);
            let tealiumAdobeID = searchParams.get('a_v_id');

            if(!tealiumAdobeID){
                 //Check the cookie for tealium Adobe ID
                tealiumAdobeID = getCookieByName('s_ecid');

                if(!tealiumAdobeID){
                    tealiumAdobeID = await fetchAbodeMarketVisitorID();
                }

            }else{
                deleteCookie("s_ecid");
                setCookie("s_ecid", tealiumAdobeID, getTopDomainName() , 183 );
                 /* Remove a_v_id query parameter */
                if( history ){
                    const newURL = new URL(window.location.href);
                    newURL.searchParams.delete('a_v_id');
                    if( history.state ){
                       history.replaceState(history.state, history.context, newURL);
                    }else{
                       history.replaceState(null, '', newURL);
                    }
                }
            }

            if(tealiumAdobeID){
                data["visitor_id_aecid"] = tealiumAdobeID;
            }else{
               console.warn('Tealium abode visitor ID is missing');
            }
        }else if (getCookieByName('s_ecid') != data['visitor_id_aecid']){
            deleteCookie("s_ecid");
            setCookie("s_ecid", data['visitor_id_aecid'], getTopDomainName() , 183 );
        }

        /* Set visitor_id_aecid_group (Adobe Group Visitor ID)*/
        if(!data['visitor_id_aecid_group']){
             //Check the cookie for tealium Adobe ID
             let tealiumGroupAdobeID = getCookieByName('visitor_id_aecid_group');
             if(!tealiumGroupAdobeID){
                 tealiumGroupAdobeID = await fetchAbodeGroupVisitorID();
             }

             if(tealiumGroupAdobeID){
                data["visitor_id_aecid_group"] = tealiumGroupAdobeID;
             }else{
                console.warn('Tealium abode vodafone group ID is missing');
             }
        }else if (getCookieByName('visitor_id_aecid_group') != data['visitor_id_aecid_group']){
            deleteCookie("visitor_id_aecid_group");
            setCookie("visitor_id_aecid_group", data['visitor_id_aecid_group'], getTopDomainName() , 183 );
         }

        const xhr = new XMLHttpRequest();
        xhr.open("POST", _config.api.endpoint);
        xhr.send(JSON.stringify(data));
    }



    /**
 * Tracks a page view event.
 * @param {string} pageName - The name of the page being viewed.
 * @param {object} [details={}] - Additional details for the page view.
 * @param {string} [details.flowName] - The name of the flow.
 * @param {string} [details.flowType] - The type of the flow.
 */
 const trackPageView = (pageName, details = {}) => {
    if (!_active) {
        return;
    }

    const payload = {
        ..._config.commonEntries,
        tealium_event_type: "view",
        journey_name: details.flowName,
        journey_type: details.flowType,
        page_name: pageName,
    };
    sendOneEvent(payload);
  }

  /**
 * Tracks a event.
 * @param {string} pageName - The name of the page being viewed.
 * @param {object} [details={}] - Additional details for the event.
 * @param {string} [details.eventAction] - The type of the event action (eg onclick).
 * @param {string} [details.eventCategory] - The event category (eg button, link).
 * @param {string} [details.eventLabel] - The label of the view being interacted with.
 * @param {string} [details.flowName] - The name of the flow (for idp it will be always login).
 * @param {string} [details.flowType] - The type of the flow (for idp it will be always login).
 */
function trackPageEvent(pageName, details = {}) {
    if (!_active) {
        return;
    }

    const payload = {
        ..._config.commonEntries,
        tealium_event_type: "event",
        event_action: "onclick",
        event_category: details.eventCategory,
        event_label: details.eventLabel,
        journey_name: "login",
        journey_type: "login",
        page_name: pageName,
        event_name: 'ui_interaction'
    };
    sendOneEvent(payload);
}

  return {
      initializeTealium,
      setTealiumActive,
      trackPageView,
      trackPageEvent
  }

}

export default analyticsTealium;