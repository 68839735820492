import React from "react";
import "./error.scss";

const Error = props => {
    const title = props.title ? props.title : 'Something went wrong';
	return (
        <>
            <div className="vf-error-wrapper">
                <div className="vf-error">
                    <div className="vf-error-icon-wrapper">
                        <div className="vf-error-icon"></div>
                    </div>
                    <div className="vf-error-title">{title}</div>
                    <div className="vf-error-text">{props.text}</div>
                </div>
            </div>
        </>
	);
}

export default Error;
